import React from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

const YearlyCompiledCard = ({
  borrower,
  categoryname,
  di_amount,
  due_amount,
  rp_amount,
  principle_due,
  principle_outstanding,
  int_due_amount,
  to_print,
  ioi_due,
  penal_due,
}) => {
  return (
    <section>
      <div className=" border border-slate-300  bg-slate-50">
        <div className={`${to_print ? "p-3" : "p-6"}`}>
          <div
            className={`${
              to_print
                ? "text-[12px]"
                : " text-lg md:text-xl p-1 border bg-slate-100 "
            }  text-primary font-semibold text-center `}
          >
            {categoryname}
          </div>
          <div className={`${to_print ? "mt-3" : "mt-6"}`}>
            <Element label={"Borrowers"} value={borrower || 0} />
            <Element label={"Disbursed"} value={formatINRCurrency(di_amount)} />
            <Element
              label={"Payment Received"}
              value={formatINRCurrency(rp_amount)}
            />
            <Element
              label={"Due Amount"}
              value={formatINRCurrency(due_amount)}
            />
            <Element
              label={"Principle Due"}
              value={formatINRCurrency(principle_due)}
            />
            <Element
              label={"Principle Outstanding"}
              value={formatINRCurrency(principle_outstanding)}
            />
            <Element
              label={"Interest Due"}
              value={formatINRCurrency(int_due_amount)}
            />

            {ioi_due == 0 ? null : (
              <>
                <Element
                  label={"Interest on Interest Due"}
                  value={formatINRCurrency(ioi_due)}
                />
              </>
            )}
            {ioi_due == 0 ? null : (
              <>
                <Element
                  label={"Penal Interest Due"}
                  value={formatINRCurrency(penal_due)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const Element = ({ label, value }) => {
  return (
    <div className="p-1">
      <div className="grid grid-cols-2 items-center border-b">
        {label}: <span className="font-bold"> {value}</span>
      </div>
    </div>
  );
};

export default YearlyCompiledCard;
