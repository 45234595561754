import React, { useEffect, useState } from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { button, custom_width_button } from "../../../theme/lightTheme";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  CREATE_ORDER_API,
  REPAYMENT_lOGIN_URL,
} from "../../../api/api_routing_urls";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import BillDeskSdk from "../Pages/paymentGateway/billDeskSDK.component";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import axios from "../../../api/axios";
import { useLocation } from "react-router-dom";

export default function Noc_online_payment() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("payment");

  const [paymentAmount, setPaymentAmount] = useState();
  const [orderObj, setOrderObj] = useState({});
  const [lunchSDK, setLunchSDK] = useState(false);
  const [showSDK, setShowSDK] = useState(false);
  const [fingerprintId, setFingerprintId] = useState(null);

  const [txnDetails, setTxnDetails] = useState();

  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    // defaultValues: principleDueWithIntData,
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;

  const onSubmit = async (data) => {
    createOrderApi(data);
  };

  const createOrderApi = async (data) => {
    const timestamp = moment().format("YYYYMMDDHHmmss");
    // const ORDERNO = `${timestamp}8056`;
    // const ORDERNO = `${loaneeBasicDetails?.loanee_id}${timestamp}`;
    const ORDERNO = `A0020${timestamp}`;

    try {
      const payload = {
        orderid: ORDERNO,
        amount: parseFloat(data?.paymentamount).toFixed(2),
        currency: "356",
        ru: process.env.REACT_APP_PAYMENT_RESPONSE_URL,
        additional_info: {
          additional_info1: `${applicantDetails.phoneNo}`,
          additional_info2: `${applicantDetails.serialNo}`,
        },
        device: {
          init_channel: "internet",
          user_agent: userAgent,
          accept_header: "text/html",
          browser_tz: new Date().getTimezoneOffset().toString(),
          browser_color_depth: window.screen.colorDepth.toString(),
          fingerprintid: fingerprintId,
          browser_java_enabled: "false",
          browser_screen_height: window.screen.height.toString(),
          browser_screen_width: window.screen.width.toString(),
          browser_language: language,
          browser_javascript_enabled: navigator.userAgent.includes("JavaScript")
            ? "true"
            : "false",
        },
      };

      const response = await axiosPrivate.post(
        `${CREATE_ORDER_API}/createOrderAPI_NOCApplicant`,
        {
          payload: JSON.stringify(payload),
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content-type header
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        const ordObj = {
          merchantId: response?.data?.createOrderAPI?.mercid,
          bdOrderId: response?.data?.createOrderAPI?.bdorderid,
          authToken:
            response?.data?.createOrderAPI?.links[1]?.headers?.authorization,
        };
        setOrderObj(ordObj);
        setLunchSDK(true);
      }
    } catch (error) {
      console.log("error", error);
      console.error("createOrderApi", error);
      setLunchSDK(false);
      // navigation.navigate("ErrorScreen");
    }
  };

  const transactionDetailsWithPhoneNo = async () => {
    try {
      const response = await axios.post(
        `${REPAYMENT_lOGIN_URL}/getTxnDetailsWithPhoneNo`,
        {
          phone_no: applicantDetails.phoneNo,
        }
      );

      setTxnDetails(
        response?.data?.txnDetailsByPhoneNo
          ?.get_payment_transaction_details_by_phone_no
      );
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the range of 2xx
        console.log("Response error:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request error:", error.request);
      } else {
        // Something else happened in setting up the request
        console.log("Error message:", error.message);
      }
    }
  };

  useEffect(() => {
    transactionDetailsWithPhoneNo();
  }, []);

  useEffect(() => {
    if (lunchSDK) {
      setShowSDK(true);
    }
  }, [orderObj, lunchSDK]);

  const { applicantDetails } = location.state || {};

  return (
    <>
      {showSDK && orderObj ? (
        <>
          <HeroBanner>
            <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
              <div className="text-center">
                <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                  NOC Payment
                </p>
              </div>
            </div>
          </HeroBanner>
          <div className="flex items-center justify-center w-full mx-auto bg-white">
            <BillDeskSdk
              orderObj={orderObj}
              responseUrl={process.env.REACT_APP_PAYMENT_RESPONSE_URL}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <HeroBanner>
              <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
                <div className="text-center">
                  <H1>NOC Online Payment</H1>
                </div>
              </div>
            </HeroBanner>
          </div>
          <div>
            <section>
              <div className="mx-10 my-10">
                <div className="flex justify-start items-center gap-6">
                  <span
                    className={`cursor-pointer text-xs sm:text-sm ${
                      activeTab === "payment"
                        ? "text-primary  border-b-4 border-[#001D31] p-2 bg-slate-100 font-medium rounded"
                        : "transition-all duration-300 text-slate-700 font-medium border border-slate-300 p-2 rounded"
                    }`}
                    onClick={() => setActiveTab("payment")}
                  >
                    Make Payment
                  </span>
                  /
                  <span
                    className={`cursor-pointer text-xs sm:text-sm ${
                      activeTab === "history"
                        ? "text-primary border-b-4 border-[#001D31] p-2 bg-slate-100 font-medium rounded"
                        : "transition-all duration-300 text-slate-500 font-medium border border-slate-300 p-2 rounded"
                    }`}
                    onClick={() => setActiveTab("history")}
                  >
                    Payment History
                  </span>
                </div>
              </div>
            </section>
          </div>

          {activeTab === "payment" && (
            <>
              <>
                <div className="mx-10 my-10 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2 text-xs md:text-base">
                      <Input
                        defaultName="paymentamount"
                        register={register}
                        name="Payment Amount"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter payment amount"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="number"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={(e) => {
                          setPaymentAmount(e);
                        }}
                        defaultValue={150}
                        setValue={setValue}
                        max={150}
                        min={150}
                      />
                    </fieldset>

                    <p className="text-xs md:text-sm italic font-medium text-pretty text-primary pt-1">
                      "The payment amount is fixed at ₹ 150/- .No other amount
                      can be entered."
                    </p>
                    <div className="flex justify-between mt-6  ">
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        label={"Proceed to pay"}
                        className={`${custom_width_button} text-xs md:text-base md:w-max w-[100%]`}
                      />
                    </div>
                  </form>
                </div>
              </>
            </>
          )}

          {activeTab === "history" && (
            <section className="mx-6 my-10  overflow-y-hidden  ">
              <table className="min-w-full divide-y divide-slate-200 overflow-y-hidden ">
                <thead className="bg-slate-200 text-sm ">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-slate-900  "
                    >
                      SI No
                    </th>
                    <th
                      scope="col"
                      className="px-3  text-left font-medium text-slate-900  "
                    >
                      TR No
                    </th>
                    <th
                      scope="col"
                      className="px-3  text-left font-medium text-slate-900  "
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3  text-left font-medium text-slate-900  "
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3  text-left font-medium text-slate-900 "
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3  text-center font-medium text-slate-900 "
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-slate-400 text-slate-600 ">
                  {txnDetails?.length > 0 ? (
                    txnDetails?.map((txnDetails, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2 whitespace-nowrap text-sm">
                          {index + 1}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm">
                          {txnDetails?.orderno}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm">
                          {getFormattedDateDDMMMYYYYDate(
                            txnDetails?.txn_json_data?.transaction_date ||
                              txnDetails?.txn_json_data?.order_date
                          )}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm">
                          {txnDetails?.txn_json_data?.amount || "--"}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm">
                          {txnDetails?.txn_json_data?.auth_status === "0300" ? (
                            <p className="text-green-500">Success</p>
                          ) : txnDetails?.txn_json_data?.auth_status ===
                            "0002" ? (
                            <p className=" text-orange-500"> Pending</p>
                          ) : txnDetails?.txn_json_data?.auth_status ===
                            "0399" ? (
                            <p className="text-red-500"> Failed</p>
                          ) : (
                            <p className="text-orange-500"> Pending</p>
                          )}
                        </td>

                        <td className="px-3 py-2 whitespace-nowrap text-xs text-center">
                          {txnDetails?.txn_json_data?.auth_status === "0300" ? (
                            <FaRegCircleCheck size={20} color="green" />
                          ) : txnDetails?.txn_json_data?.auth_status ===
                            "0002" ? (
                            <p className=" text-sm  ">Check Status</p>
                          ) : txnDetails?.txn_json_data?.auth_status ===
                            "0399" ? (
                            <RxCrossCircled size={20} color="red" />
                          ) : (
                            <p className="inline text-sm cursor-pointer border px-3 py-2 rounded bg-slate-800 text-slate-100 hover:bg-slate-950">
                              Check Status
                            </p>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={8} className="p-4 text-center">
                      {" "}
                      No Record Found
                    </td>
                  )}
                </tbody>
              </table>
            </section>
          )}
        </>
      )}
    </>
  );
}
