import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateDDMMYYYY,
} from "../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import { number_in_words } from "../../../utilities/generalFunctions/numberToWrod";
import { PrintReport } from "../ReportsList/PrintReport/PrintReport";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import { useForm } from "react-hook-form";

export default function ViewDemand_Notice({
  setShowNotice,
  loaneReferenceNumberDetails,
  loanNumber,
  totalDueAmount,
  principleDueWithIntData,
  loaneeDetails,
  penalInterest_Amount,
  ioiTotalDue,
  showFinalNotice,
  guarantorNamesDD,
  selectedGuarantor,
  setSelectedGuarantor,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  let TotalOfScheduleI;
  let TotalOfScheduleII;

  if (loaneeDetails?.categoryname === "OBC") {
    TotalOfScheduleI =
      +principleDueWithIntData?.principle_due +
      +principleDueWithIntData?.interest_due_amount +
      +penalInterest_Amount +
      +ioiTotalDue;
  } else {
    TotalOfScheduleI =
      +principleDueWithIntData?.principle_due +
      +principleDueWithIntData?.interest_due_amount;
  }

  if (loaneeDetails?.categoryname === "OBC") {
    TotalOfScheduleII =
      +principleDueWithIntData?.principle_outstanding_amount +
      +principleDueWithIntData?.interest_due_amount +
      +penalInterest_Amount +
      +ioiTotalDue;
  } else {
    TotalOfScheduleII =
      +principleDueWithIntData?.principle_outstanding_amount +
      +principleDueWithIntData?.interest_due_amount;
  }

  let TotalOfScheduleI_In_Words = number_in_words(TotalOfScheduleI);
  let TotalOfScheduleII_In_Words = number_in_words(TotalOfScheduleII);

  return (
    <>
      <div className="flex justify-between">
        <H2withIcon>
          <MdAssignmentTurnedIn />{" "}
          <span className="pl-1.5">
            {showFinalNotice ? "View Final Notice" : " View Demand Notice"}
          </span>
        </H2withIcon>
        <div className="flex items-center gap-2">
          <section>
            <PrintReport
              reportTitle={showFinalNotice ? "Final Notice" : " Demand Notice"}
              buttonName={showFinalNotice ? "Final Notice" : " Demand Notice"}
            >
              <NoticeTemplate
                loaneReferenceNumberDetails={loaneReferenceNumberDetails}
                loaneeDetails={loaneeDetails}
                showFinalNotice={showFinalNotice}
                TotalOfScheduleI={TotalOfScheduleI}
                principleDueWithIntData={principleDueWithIntData}
                TotalOfScheduleI_In_Words={TotalOfScheduleI_In_Words}
                penalInterest_Amount={penalInterest_Amount}
                ioiTotalDue={ioiTotalDue}
                TotalOfScheduleII={TotalOfScheduleII}
                TotalOfScheduleII_In_Words={TotalOfScheduleII_In_Words}
                selectedGuarantor={selectedGuarantor}
              />
            </PrintReport>
          </section>

          <span
            className="flex items-center cursor-pointer hover:underline underline-offset-2 "
            onClick={() => setShowNotice(false)}
          >
            <BiChevronLeft size={24} /> back
          </span>
        </div>
      </div>
      <Dropdown
        defaultName="copyTo"
        register={register}
        labelname="Copy to"
        required={false}
        pattern={null}
        errors={errors}
        classes={`rounded-sm  py-2 text-sm w-[25%] `}
        setError={setError}
        clearError={clearErrors}
        onChangeInput={null}
        control={control}
        data={guarantorNamesDD}
        defaultValue={null}
        setValue={setValue}
        setSelected={setSelectedGuarantor}
        selected={selectedGuarantor}
        maxMenuHeight={120}
      />

      <NoticeTemplate
        loaneReferenceNumberDetails={loaneReferenceNumberDetails}
        loaneeDetails={loaneeDetails}
        showFinalNotice={showFinalNotice}
        TotalOfScheduleI={TotalOfScheduleI}
        principleDueWithIntData={principleDueWithIntData}
        TotalOfScheduleI_In_Words={TotalOfScheduleI_In_Words}
        penalInterest_Amount={penalInterest_Amount}
        ioiTotalDue={ioiTotalDue}
        TotalOfScheduleII={TotalOfScheduleII}
        TotalOfScheduleII_In_Words={TotalOfScheduleII_In_Words}
        selectedGuarantor={selectedGuarantor}
      />
    </>
  );
}

export const NoticeTemplate = ({
  loaneReferenceNumberDetails,
  loaneeDetails,
  showFinalNotice,
  TotalOfScheduleI,
  principleDueWithIntData,
  TotalOfScheduleI_In_Words,
  penalInterest_Amount,
  ioiTotalDue,
  TotalOfScheduleII,
  TotalOfScheduleII_In_Words,
  selectedGuarantor,
}) => {
  return (
    <>
      <section className=" text-[14px] px-6 ">
        <div className="flex justify-between mb-3">
          <p className="font-semibold">
            Ref.No.:{loaneReferenceNumberDetails?.reference_number}
          </p>
          <p className="font-semibold">
            Date :
            {getFormattedDateDDMMMYYYYDate(
              loaneReferenceNumberDetails?.generated_date
            )}{" "}
          </p>
        </div>
        <div className="my-6">
          To,
          <p className="font-semibold">{loaneeDetails?.applicantname}</p>
          <p className="font-semibold uppercase">
            {loaneeDetails?.applicantrelative}:&nbsp;
            {loaneeDetails?.applicantrelativename}
          </p>
          <p className="font-semibold uppercase">
            {loaneeDetails?.permanentaddress}
          </p>
        </div>

        <div className="underline font-semibold ">
          Subject:{" "}
          {showFinalNotice
            ? "Final Notice for repayment of loan."
            : " Demand Notice for repayment of loan."}
        </div>
        <div className="mt-6">
          <p>Dear Sir/Madam, </p>
          <p className="mt-4">
            This is to inform you that a sum of
            <span className="font-semibold">
              {" "}
              {formatINRCurrency(TotalOfScheduleI)}
              &nbsp;
            </span>
            has fallen due for repayment against your &nbsp;
            <span className="font-semibold">
              {loaneeDetails?.schemename}
            </span>{" "}
            loan ID.No:{" "}
            <span className="font-semibold">{loaneeDetails?.loanee_id} </span>{" "}
            account with this Corporation. The detail of the amount payabale as
            on{" "}
            <span className="font-semibold">
              {" "}
              {getFormattedDateDDMMYYYY(
                loaneReferenceNumberDetails?.generated_date
              )}
            </span>{" "}
            by you is given in the schedule below :
          </p>
        </div>
        <div className="flex flex-cols-2 justify-around pt-4 ">
          <div>
            <h2 className="underline underline-offset-2 font-semibold">
              Schedule I
            </h2>
            <ul className="list-decimal pt-2">
              <li>Principle Default</li>
              <li>Interest due</li>
              {loaneeDetails?.categoryname === "OBC" ? (
                <>
                  <li>Penal Interest</li>
                  <li>Interest on Interest</li>
                </>
              ) : null}
            </ul>
          </div>
          <div>
            <h2 className="underline underline-offset-2 font-semibold ">
              Amount
            </h2>
            <ul className="pt-2">
              <li className="font-semibold">
                {formatINRCurrency(principleDueWithIntData?.principle_due)}
              </li>
              <li className="font-semibold">
                {formatINRCurrency(
                  principleDueWithIntData?.interest_due_amount
                )}
              </li>
              {loaneeDetails?.categoryname === "OBC" ? (
                <>
                  <li className="font-semibold">
                    {formatINRCurrency(penalInterest_Amount)}
                  </li>
                  <li className="font-semibold">
                    {formatINRCurrency(ioiTotalDue)}
                  </li>
                </>
              ) : null}
            </ul>
            <span className="font-semibold">
              Total :{" "}
              <span className="font-semibold">
                {formatINRCurrency(TotalOfScheduleI)}
              </span>
            </span>
          </div>
        </div>
        <p className="pt-4 ml-10 font-semibold">
          (Rupees <span className="font-bold">{TotalOfScheduleI_In_Words}</span>
          only)
        </p>
        <div className="flex flex-col-2 justify-around pt-4">
          <div>
            <h2 className="underline underline-offset-2 pt-2 font-semibold">
              Schedule II
            </h2>
            <ul className="list-decimal pt-2">
              <li>Principle Outstanding</li>
              <li>Interest due</li>

              {loaneeDetails?.categoryname === "OBC" ? (
                <>
                  <li>Penal Interest</li>
                  <li>Interest on Interest</li>
                </>
              ) : null}
            </ul>
          </div>
          <div>
            <h2 className="underline underline-offset-2 mt-2 font-semibold">
              Amount
            </h2>
            <ul className="pt-2">
              <li className="font-semibold">
                {" "}
                {formatINRCurrency(
                  principleDueWithIntData?.principle_outstanding_amount
                )}
              </li>
              <li className="font-semibold">
                {formatINRCurrency(
                  principleDueWithIntData?.interest_due_amount
                )}
              </li>
              {loaneeDetails?.categoryname === "OBC" ? (
                <>
                  <li className="font-semibold">
                    {formatINRCurrency(penalInterest_Amount)}
                  </li>
                  <li className="font-semibold">
                    {formatINRCurrency(ioiTotalDue)}
                  </li>
                </>
              ) : null}
            </ul>
            <span className="font-semibold">
              {" "}
              Total :{" "}
              <span className="font-semibold">
                {formatINRCurrency(TotalOfScheduleII)}
              </span>
            </span>
          </div>
        </div>

        <p className="pt-4 ml-10 font-semibold">
          (Rupees{" "}
          <span className="font-bold">{TotalOfScheduleII_In_Words}</span> only)
        </p>
        <p className="mt-6">
          You are advised to settlle the overdues indicated in the above
          schedule within 15 days from date of receipt of this notice in the
          spirit of agreement executed by you and thereby avoid Complication of
          any kind. In case you fail to repay the aforestated amount within the
          stipulated period the Corporation may take legal action under the SPDR
          Act 1988/Lok Adalat. <br />
          Soliciting your kind co-operation
        </p>

        <div className="flex flex-row justify-between pt-10">
          <div>
            <ul>
              <li>Name & Signature of the recipients:</li>
              <li className=" font-semibold  mt-16">
                {loaneeDetails?.applicantname}
              </li>

              <p className="font-semibold uppercase ">
                {loaneeDetails?.permanentaddress}
              </p>

              {selectedGuarantor ? (
                <>
                  <p className="pt-6">Copy To </p>
                  <p>
                    Guarantor Name:
                    <span className="font-semibold">
                      {selectedGuarantor?.label}
                    </span>
                  </p>
                  <p>
                    Guarantor Address:
                    <span className="font-semibold">
                      {selectedGuarantor?.address}
                    </span>
                  </p>
                </>
              ) : null}
            </ul>
          </div>
          <div>
            <p className="text-right">Yours faithfully,</p>
            <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
          </div>
        </div>
      </section>
    </>
  );
};
