import React, { useEffect, useState, Fragment, useMemo } from "react";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  OTS_CONFIG_URL,
  SCHEME_CONFIG_URL,
  LOANEE_DEATH_CASE,
} from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  button,
  linkTextDanger,
  linkTextPrimary,
  linkTextSecondary,
} from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";
import { useDetectClickOutside } from "react-detect-click-outside";
import useAuth from "../../../../authentication/hooks/useAuth";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import {
  MdCheck,
  MdOutlineApproval,
  MdOutlinePendingActions,
  MdPhoneIphone,
  MdRemoveRedEye,
} from "react-icons/md";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { FaEdit, FaUser } from "react-icons/fa";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { OtsCalculation } from "../applicant-components/OtsCalculation";
import { SubMenu } from "../applicant-components/SubMenu";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import useNotificationHook from "../../../../authentication/hooks/useNotificationHook";

export default function RejectedApplicantList({
  setEditGuarantorDetails,
  setShowAddGuarantorForm,
  applicantSearchedList,

  setLoaneeName,
}) {
  const { auth } = useAuth();

  const { notificationDetail } = useNotificationHook();
  const notificationData = notificationDetail?.notificationDetails?.list || {};

  const viewGuarantorDetail = (guarantorDetail, applicantName) => {
    try {
      setEditGuarantorDetails(guarantorDetail);
      setLoaneeName(applicantName);
      setShowAddGuarantorForm(true);
    } catch (error) {
      console.error("viewGuarantorDetail", error);
    }
  };

  // onClick to convert to death case

  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-xs  md:text-sm ">
          <tr className="bg-slate-200 sticky top-0 z-0">
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loanee ID
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium flex flex-col min-w-44"
            >
              <span>Applicant Name</span>{" "}
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Contact
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Scheme
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loan Amount
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Sanction Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Approval Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Applicant Guarantors
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {applicantSearchedList?.length > 0 ? (
            applicantSearchedList?.map((RejectedApplicantDetail, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                  <div className="flex items-center justify-start gap-2 z-0">
                    <div className="relative w-3 h-3">
                      {notificationData?.length > 0 &&
                        notificationData?.map((obj, index) =>
                          +obj.loanee_id ===
                          +RejectedApplicantDetail?.loanee_id ? (
                            <span
                              key={index}
                              className="w-3 h-3 rounded-full bg-red-500 absolute"
                            ></span>
                          ) : null
                        )}
                    </div>
                    <p>{RejectedApplicantDetail?.loanee_id}</p>
                  </div>
                  <div className="bg-blue-600 text-center rounded">
                    <p className="text-center font-medium text-white">
                      {RejectedApplicantDetail?.is_draft ? "Draft" : null}
                    </p>
                  </div>
                </td>
                <td className="px-3 py-1.5 text-xs 2xl:text-sm ">
                  <p className="flex flex-col text-left ">
                    <span>{RejectedApplicantDetail?.applicantname}</span>

                    <span className="font-semibold text-xs 2xl:text-sm">
                      ({RejectedApplicantDetail?.categoryname})
                    </span>
                  </p>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm ">
                  <span className="flex gap-0.5">
                    <span className="mt-0.5 text-primary">
                      <MdPhoneIphone size={15} />
                    </span>

                    {RejectedApplicantDetail?.contactno > 0
                      ? RejectedApplicantDetail?.contactno
                      : "---"}
                  </span>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {/* {`${RejectedApplicantDetail?.schemename} (${RejectedApplicantDetail?.schemeshortname})`} */}
                  {`${
                    RejectedApplicantDetail?.schemename ||
                    RejectedApplicantDetail?.schemeshortname ||
                    "---"
                  }`}
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {RejectedApplicantDetail?.loanappliedamt >=
                  RejectedApplicantDetail?.loan_amount
                    ? RejectedApplicantDetail?.loanappliedamt
                    : RejectedApplicantDetail?.loan_amount}
                </td>

                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                  <div className="flex w-12 justify-between py-0.5">
                    Pre:
                    {RejectedApplicantDetail?.is_presanction_revert === true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Reverted</span> */}
                        <TbArrowBackUp size={18} />
                      </span>
                    ) : RejectedApplicantDetail?.is_presanction_approved ===
                        true || RejectedApplicantDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Pending</span> */}
                        <MdOutlinePendingActions size={18} />
                      </span>
                    )}
                  </div>
                  <div className="flex w-12 justify-between py-0.5">
                    Post:
                    {RejectedApplicantDetail?.is_postsanction_revert ===
                    true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        <TbArrowBackUp size={18} />
                        {/* <span className="text-slate-700">Reverted</span> */}
                      </span>
                    ) : RejectedApplicantDetail?.is_postsanction_approved ===
                        true || RejectedApplicantDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        <MdOutlinePendingActions size={18} />
                        {/* <span className="text-slate-700">Pending</span> */}
                      </span>
                    )}
                  </div>
                </td>
                {auth.rolename !== "Content Admin" && (
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                    {RejectedApplicantDetail?.is_presanction_approved !==
                      true && RejectedApplicantDetail?.loanee_id > 7761 ? (
                      <>
                        {auth.rolename !==
                          RejectedApplicantDetail?.pre_forwarded_to &&
                        RejectedApplicantDetail?.pre_forwarded_to !== null ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {RejectedApplicantDetail?.is_postsanction_approved ===
                          true || RejectedApplicantDetail?.loanee_id <= 7761 ? (
                          <>
                            <span className="text-green-600 flex gap-1 items-center">
                              <span className="text-slate-700">Disbursed</span>
                              <MdCheck size={18} />
                            </span>
                          </>
                        ) : (auth.rolename !==
                            RejectedApplicantDetail?.post_forwarded_to &&
                            RejectedApplicantDetail?.post_forwarded_to !==
                              null) ||
                          auth.rolename === "Managing Director" ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    )}
                  </td>
                )}
                <td className="px-3 py-1.5 whitespace-nowrap text-xs  md:text-sm ">
                  {RejectedApplicantDetail?.guarantordetails !== null ? (
                    <>
                      <ul>
                        {RejectedApplicantDetail?.guarantordetails?.map(
                          (guarantorDetail, index) => (
                            <li
                              key={index}
                              className={`py-0.5 text-xs  flex 
                              whitespace-nowrap ${
                                (auth.rolename === "Data Entry Operator" ||
                                  auth.rolename === "Super Admin") &&
                                !RejectedApplicantDetail?.is_presanction_approved &&
                                ` cursor-pointer `
                              }`}
                              onClick={() => {
                                (auth.rolename === "Data Entry Operator" ||
                                  auth.rolename === "Super Admin") &&
                                  !RejectedApplicantDetail?.is_presanction_approved &&
                                  viewGuarantorDetail(
                                    guarantorDetail,
                                    RejectedApplicantDetail?.applicantname
                                  );
                              }}
                            >
                              <span className="mt-0.5">
                                <FaUser size={12} />
                              </span>
                              <div
                                className={` flex text-xs justify-between w-full  ${
                                  (auth.rolename === "Data Entry Operator" ||
                                    auth.rolename === "Super Admin") &&
                                  !RejectedApplicantDetail?.is_presanction_approved
                                    ? `${linkTextSecondary}  `
                                    : `text-slate-600  px-2`
                                }`}
                              >
                                <div>
                                  {guarantorDetail?.guarantorname.substring(
                                    0,
                                    19
                                  )}{" "}
                                  ...
                                </div>
                                {!RejectedApplicantDetail?.is_presanction_approved && (
                                  <>
                                    {(auth.rolename === "Data Entry Operator" ||
                                      auth.rolename === "Super Admin") &&
                                      !RejectedApplicantDetail?.is_presanction_approved && (
                                        <FaEdit size={14} />
                                      )}
                                  </>
                                )}
                              </div>
                            </li>
                          )
                        )}
                      </ul>{" "}
                    </>
                  ) : (
                    "---"
                  )}
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr className="m-auto text-center">
                <td colSpan={9} className="py-3">
                  No List Found
                </td>
              </tr>
            </>
          )}
        </tbody>
      </TableWrapper>
    </>
  );
}
