import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function OrganizationAndFunctionPage() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Organization & Functions</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        {/* <div className="border  inline-flex justify-end items-center gap-4">
          <span>
            <IoReturnDownBack />
          </span>
          <span>back</span>
        </div> */}

        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 lg:mx-0 py-10 lg:py-0 text-xs md:text-sm">
          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2">
            Organization Details
          </p>

          <p>
            <span className="font-semibold "> Name of Organization : </span>
            Sikkim Scheduled Castes, Scheduled Tribes and Other Backward Classes
            Development Corporation Ltd. (SABCCO)
          </p>
          <p className="">
            <span className="font-semibold ">Address :</span> Sonam Tshering
            Marg, Gangtok, Sikkim-737101.
          </p>
          <p className="">
            <span className="font-semibold">Head of the Organisation : </span>{" "}
            Managing Director
          </p>

          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2 pt-6">
            Nature of function/services offered norms/standards for function
          </p>
          <p>
            Sikkim Scheduled Castes, Scheduled Tribes and other Backward Classes
            Development Corporation Ltd. (SABCCO) under the aegis of Social
            Justice, Empowerment and Welfare Department, Government of Sikkim
            was established under the Companies Act (Sikkim), 1961 on 27th
            February 1996. The Corporation is a fully owned Government
            Undertaking.
          </p>
          <p>
            It is a State Channelising Agency (SCA) which avails financial
            assistance by way of loan from Apex Corporations, viz. The National
            Scheduled Castes Finance and Development Corporation (NSFDC),
            National Scheduled Tribes Finance and Development Corporation
            (NSTFDC), National Backward Classes Finance and Development
            Corporation (NBCFDC), National Handicapped Finance and Development
            Corporation (NHFDC), and National Minorities Development and Finance
            Corporation (NMDFC) for financing income generating schemes for the
            Scheduled Castes, Scheduled Tribes, Other Backward Classes, Persons
            with Disability and Minorities. The Corporation identifies
            beneficiaries and the viable schemes as per the felt need of the
            beneficiaries, disburses loans. Monitors the utilization of loans by
            these beneficiaries and timely recovery of the loans.
          </p>

          <p>
            hese Apex Corporations provide Term Loan Assistance to SABCCO for
            further lending to its beneficiaries. SABCCO avail Term Loan @ 3-4%
            rate of interest from Apex Corporations and charge maximum simple
            interest of 6%. Simple interest is charged on Principle reducing
            basis.
          </p>

          <p className="pt-6 font-semibold">TARGET GROUP</p>

          <p
            className="pt-4
          "
          >
            Scheduled Castes, Scheduled Tribes, Other Backward Classes and
            Persons with Disabilities and Minorities belonging to low income
            group in the rural and urban areas of Sikkim with special focus on
            educated unemployed and women and promoting economic development
            activities.
          </p>

          <p className="pt-6 font-semibold">ELIGIBILITY CRITERIA</p>

          <p>
            For SCs, STs, OBCs, PWDs & Minorities
            <p>
              a) Beneficiary should be SC, ST, OBC, PWD and Minorities domiciled
              in Sikkim or a Cooperative Society or a legally constituted
              Association/Firms promoted by members of SC, ST, OBC, PWD and
              Minorities.
            </p>
            <p>
              b) Annual income of the beneficiary or members of a Cooperative
              Society or legally constituted association/firms should not exceed
              Rs. 3,00,000/- per annum.
            </p>
          </p>

          <p className="font-semibold py-2">For Handicapped/disabled</p>

          <ul className="pl-4 list-disc">
            <li>Any person with 40% or more disability. </li>
            <li>Age between 18 to 55 years.</li>
            <li>A cooperative society of disabled persons.</li>
            <li>A legally constituted association of disabled persons.</li>
            <li>A firm promoted by disabled persons.</li>
            <li>
              Each member of society/association /firm applying for loan should
              fulfil the disability, age and income criteria.
            </li>

            <li>
              Relevant educational/technical/vocational qualification,
              experience & background.
            </li>
          </ul>

          <p className="font-semibold py-4">FINANCE PROGRAMME</p>
          <p className="font-semibold ">Term Loan</p>

          <p>
            The Corporation extends finance by way of term loan for a wide range
            of income generating activities, but does not finance any
            infrastructure development schemes unless such schemes lead directly
            to income generation.
          </p>
          <p className="font-semibold py-2">Micro-Credit</p>

          <p className="">
            The Corporation also extends assistance to promote micro-credit
            scheme at the grass root level to the low income groups in the
            rural, semi-urban and urban areas to enable them to raise their
            income level and be self reliant. Under this scheme loans will be
            provided either directly or through NGOs and SHGs.
          </p>

          <p className="py-2">
            The NGOs will organize SHGs of micro-entrepreneurs, arrange skill
            development training programmes and extend financial assistance to
            these groups. The Corporation will extend loans to the NGOs for
            undertaking such activities.
          </p>

          <p className="font-semibold py-2">SCHEMES FOR ASSISTANCE</p>

          <ul
            style={{ listStyleType: "lower-alpha" }}
            className="pl-4 md:pl-10 py-4"
          >
            <li>
              <span className="font-semibold">Agriculture and Allied:</span>
              Agriculture production and related field such as purchase of
              agriculture machinery and implements, animal breeding, poultry,
              dairy, piggery and bee-keeping. Horticulture, floriculture,
              mushroom cultivation, cash crops like cardamom, orange apple,
              potato, ginger, etc.
            </li>

            <li>
              <span className="font-semibold">
                Artisan and Traditional Occupation:
              </span>{" "}
              These include handicrafts, tailoring, weaving, knitting, carpet
              weaving, jewelry, carpentry, blacksmithy, bakery, beauty parlour,
              etc.
            </li>
            <li>
              <span className="font-semibold">Technical Trades :</span>
              All self employment schemes such as electrician, plumber, steel
              fabrication works, TV/Radio repair, motor mechanic, book binding,
              clinical labs, Xerox/Fax/Typing, Lamination Centres, STD/PCO
              booths, etc.
            </li>
            <li>
              <span className="font-semibold">
                Hotel/Guest House/Lodging/Restaurant:
              </span>
              The loan facility is available to SC/ST/OBC/Persons with
              Disabilities/Minorities beneficiaries only for conversion of,
              renovation of rooms, kitchen, etc. of existing building to guest
              houses/lodging in areas having tourism potential.
            </li>
            <li>
              <span className="font-semibold">Small Business:</span>
              Loans for setting up small business ventures such as tea shops,
              general provision stores, grocery/manihari shops, readymade
              garments shop, newspaper/magazine shop etc.
            </li>
            <li>
              <span className="font-semibold">Transport Services:</span>
              Purchase of commercial vehicles for self employment.
            </li>
          </ul>

          <p className="py-2 font-semibold">PROCEDURE FOR AVAILING LOANS</p>

          <ul
            style={{ listStyleType: "lower-alpha" }}
            className="pl-4 md:pl-10 py-4"
          >
            <li>
              Submission of application form in the prescribed format (available
              in the Corporation’s Office: duly filled in along with a passport
              size photograph duly attested by a Gazetted Officer
            </li>

            <li>
              Certificate of Identification issued by District Collector/Addl.
              District Collector/Sub-Div. Magistrate.
            </li>

            <li>
              SC/ST/OBC/MBC Certificate issued by District Collector/Additional
              District Collector/Sub-Div. Magistrate.
            </li>

            <li>
              Annual family Income Certificate issued by District
              Collector/Additional District Collector/SubDiv. Magistrate.
            </li>

            <li>Land title/Parcha</li>

            <li>Salary Certificate of Gurantor</li>

            <li>
              For Persons with dissability, certificate from Medical Board
              certifying disability.
            </li>
          </ul>

          <p className="font-semibold ">PROCEDURE FOR SANCTION</p>

          <p>
            The Application received and forwarded along with the schemes to
            respective Apex Corporations for approval. On receipt of Letter of
            Intent from the Apex Corporation, the loans are sanctioned and the
            beneficiaries are informed for execution of documents required.
          </p>

          <p className="font-semibold py-2">DISBURSEMENT</p>
          <p>
            After sanction, the loan is released after the condition of sanction
            are fulfilled by the borrower
          </p>
          <p className="font-semibold py-2">SECURITY</p>

          <p>
            Loan sanctioned are disbursed only after execution of requisite
            security, related documents, creation of collateral security,
            mortgage of immovable property at adequate value and/or any other
            tangible security acceptable to the Corporation.
          </p>

          <p className="font-semibold py-2">REPAYMENT</p>

          <p>
            Repayment schedules are fixed by the Corporation after taking into
            account profitability, estimated cash generation and debt servicing
            capacity of the proposed industry and normally the repayment period
            varies from 5 to 10 years.
          </p>
          <p className="font-semibold py-2">PROCESSING FEE</p>
          <p>
            Loan processing Fee @ 1% of the sanctioned loan amount is to be
            deposited with the Corporation before the release of first
            instalment of the sanctioned amount.
          </p>
          <p className="font-semibold py-2">RATE OF INTEREST</p>

          <ul
            style={{ listStyleType: "upper-alpha" }}
            className="pl-4 md:pl-10 py-2"
          >
            <li>For Term loan upto and inclusive of Rs. 5 lakhs 6%</li>
            <li>
              Loan upto Rs. 0.45 lakh under Adivasi Mahila Sashaktikaran Yojana
              for Tribal Women. 4%
            </li>
            <li>
              Loan upto Rs. 0.50 lakh under Swarnima Scheme for MBC/OBC Women 4%
            </li>
            <li>Term loan upto Rs. 0.50 lakh for Handicapped/Disabled 6%</li>
          </ul>

          <p>
            <span className="italic">Note:</span> In the event of non-repayment
            of interest on due dates, interest on interest will be charged at
            the aforesaid rate till such interest is paid.
          </p>

          {/* <ul className="list-disc pl-4 pt-3 ">
            <li>
              To Promote economic and development activities for the benefit of
              Scheduled Castes, Scheduled Tribes and Other Backward Classes and
              Handicapped Persons.
            </li>
            <li>
              To assist individuals or groups of individuals belonging to
              Scheduled Castes, Scheduled Tribes, Other Backward Classes and
              Handicapped Persons by way of loans and advances for income and
              employment generating activities.
            </li>
            <li>
              To extend loans to Scheduled Castes, Scheduled Tribes, Other
              Backward Classes and handicapped Persons pursuing
              general/professional/technical education or training
            </li>
            <li>
              To help in furthering Government policies and programmes for the
              development of Scheduled Castes, Scheduled Tribes, Other Backward
              Classes and Handicapped Persons
            </li>
            <li className="pb-14">
              To enter into any agreement with Central Government/State
              Government of any State or local authorities or otherwise for the
              purpose of carrying out objects of the Company and to obtain from
              any such Government, State authorities or persons, any charters,
              subsidies, loans, grants etc
            </li>
          </ul>  */}
        </div>
      </section>
    </>
  );
}
