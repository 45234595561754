import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function ExcessPaymentList({ excessAmountDetails }) {
  //   console.log("excessAmountDetails", excessAmountDetails);
  return (
    <section className="pt-6">
      <section>
        <TableWrapper>
          <thead className="bg-slate-200 text-sm my-3 sticky top-0">
            <tr>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                SI No
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Access Amount
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Transaction Type
              </th>

              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Payment Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
            {excessAmountDetails?.length > 0 ? (
              excessAmountDetails.map((excessAmtObj, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? "bg-slate-100" : null}
                >
                  <td className="px-3 py-2 whitespace-nowrap">{index + 1}</td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {excessAmtObj?.payment_amount}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {excessAmtObj?.txn_type}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {getFormattedDateDDMMMYYYYDate(excessAmtObj?.payment_date)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="p-4 text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </TableWrapper>
      </section>
    </section>
  );
}
