/* eslint-disable no-unused-vars */
const paymentModeDD = [
  { value: "Half Yearly", label: "Half Yearly" },
  { value: "Yearly", label: "Yearly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Monthly", label: "Monthly" },
];
const moratiumModeDD = [
  { value: "Days", label: "Days" },
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];
const repaymentModeDD = [
  { value: "Days", label: "Days" },
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];

const applicantRelativeDD = [
  { value: "S/O", label: "S/O" },
  { value: "D/O", label: "D/O" },
  { value: "W/O", label: "W/O" },
  { value: "Others", label: "Others" },
];
const genderDD = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "N/A", label: "Others" },
];
const areaDD = [
  { value: "Rural", label: "Rural" },
  { value: "Urban", label: "Urban" },
];

const guarantorTypeDD = [
  { value: "State-Govt. Employee", label: "State-Govt. Employee" },
  { value: "Central-Govt. Employee", label: "Central-Govt. Employee" },
  { value: "Parcha Holders", label: "Parcha Holders" },
  {
    value: "Salaried with Parcha Holders",
    label: "Salaried with Parcha Holders",
  },
  { value: "Others", label: "Others" },
];
const guarantorRelationshipDD = [
  { value: "Son", label: "Son" },
  { value: "Daughter ", label: "Daughter " },
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Sister", label: "Sister" },
  { value: "Wife", label: "Wife" },
  { value: "Husband", label: "Husband" },
  { value: "Brother", label: "Brother" },
  { value: "Others", label: "Others" },
];
const designationDD = [
  { value: "Data Entry Operator", label: "Data Entry Operator" },
  { value: "Deputy General Manager", label: "Deputy General Manager" },
  { value: "General Manager", label: "General Manager" },
  { value: "Managing Director", label: "Managing Director" },
  { value: "Assistant Manager", label: "Assistant Manager" },
  { value: "Others", label: "Others" },
];

const rePaymentFormModeDD = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "Branch", label: "Branch" },
  { value: "Others", label: "Others" },
];
const rePaymentFormModeDDforOTS = [{ value: "Cash", label: "Cash" }];

const defaulterDD = [
  { value: "1 Months", label: "1 Months" },
  { value: "3 Months", label: "3 Months" },
  { value: "6 Months", label: "6 Months" },
  { value: "1 Year", label: "1 Year" },
  { value: "2 Years", label: "2 Years" },
  { value: "Exceeding 2 Years", label: "Exceeding 2 Years" },
];

const imageUploadTypeDD = [
  { value: "Capture", label: "Capture" },
  { value: "Upload", label: "Upload" },
];

const pageSizeDD = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

const loanStatusDD = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

module.exports = {
  paymentModeDD,
  moratiumModeDD,
  repaymentModeDD,
  applicantRelativeDD,
  genderDD,
  areaDD,
  guarantorTypeDD,
  guarantorRelationshipDD,
  designationDD,
  rePaymentFormModeDD,
  defaulterDD,
  imageUploadTypeDD,
  pageSizeDD,
  rePaymentFormModeDDforOTS,
  loanStatusDD,
};
