import React from "react";

import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import footerDesign from "../../../../assets/footer_Design.png";
import Section from "../../../../reusable-components/section/Section";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { number_in_words } from "../../../../utilities/generalFunctions/numberToWrod";

export default function OtsReceiptPrint({
  otsLoaneeDetails,
  otsLoaneeDue,
  loaneeData,
  receiptDetails,
  loaneeTotalRepaymentAmt,
}) {
  let totalDueAmount = +otsLoaneeDetails?.repayment_due_amt || 0;

  let total_paid_amt = +loaneeTotalRepaymentAmt + +otsLoaneeDue?.repay_amt;

  function capitalizeWords(str) {
    return str
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  return (
    <>
      <>
        {(receiptDetails?.rp_mode === "Cash" ||
          receiptDetails?.rp_mode === "Branch") && (
          <Section className=" py-4 mx-4">
            <header className=" bg-white pt-3">
              <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
                <div className="flex justify-center gap-3 items-center">
                  <img
                    className="h-28 w-28"
                    src={sabccoLogo}
                    alt="SABCCO Logo"
                  />
                </div>
              </div>
              <div>
                <p className="text-center text-[14px] font-semibold">
                  SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
                  <br />
                  CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
                  <br />
                  <span className="text-center text-[12px] font-semibold">
                    SONAM TSHERING MARG, GANGTOK
                  </span>
                </p>
              </div>
            </header>
            <body className="pt-5 bg-white">
              <div className="pt-2">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CASH RECEIPT
                </p>

                <div className="flex justify-between items-center pt-2 mx-8">
                  <p>Sl. No:{receiptDetails?.receipt_reference_number} </p>
                  <p>
                    Date:{" "}
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>

                <div className="mt-14 flex justify-center items-center tracking-widest leading-10">
                  <p className="pt-4 text-justify mx-6">
                    Received with thanks from Mr/Ms{" "}
                    <span className="font-semibold capitalize">
                      {capitalizeWords(loaneeData?.applicantname)}
                    </span>{" "}
                    <span className="font-semibold capitalize">
                      {capitalizeWords(loaneeData?.applicantrelative)}
                      &nbsp;
                    </span>
                    <span className="font-semibold capitalize">
                      {capitalizeWords(loaneeData?.applicantrelativename)}
                    </span>
                    resident of{" "}
                    <span className="font-semibold capitalize">
                      {loaneeData?.permanentaddress}&nbsp;
                    </span>
                    Loan ID No{" "}
                    <span className=" font-semibold">
                      {loaneeData?.loanee_id}
                    </span>
                    &nbsp; a sum of Rs&nbsp;
                    <span className=" font-semibold">
                      {formatINRCurrency(receiptDetails?.rp_amount)}
                    </span>
                    &nbsp;( Rupees{" "}
                    <span className="font-semibold">
                      {number_in_words(receiptDetails?.rp_amount)}
                    </span>{" "}
                    ) only on account of SABCCO loan under One-Time Settlement
                    (OTS) Scheme by cash.
                  </p>
                </div>
              </div>
            </body>
            <footer className="mt-20 mx-3">
              <div className="flex justify-end">
                <table className="text-sm font-semibold border-collapse border border-black">
                  <tbody>
                    <tr>
                      <td className="border border-black px-6">
                        Total outstanding due
                      </td>

                      <td className="border border-black px-20">
                        {formatINRCurrency(totalDueAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6">
                        Total repayment received till date
                      </td>
                      <td className="border border-black px-20">
                        {formatINRCurrency(total_paid_amt)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end items-center pt-32">
                <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
              </div>
              <div className="pt-28">
                <img src={footerDesign} alt="" srcset="" />
                <div className="text-center text-sm">
                  <p className="text-center">
                    Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                    Classes Development Corporation Ltd.
                  </p>
                  <p className="text-center">
                    Contact No: 7908335935 & 9434863433 / Website:
                    www.sikkimsabcco.com
                  </p>
                </div>
              </div>
            </footer>
          </Section>
        )}

        {/* {receiptDetails?.rp_mode === "Cheque" && (
          <Section className=" py-4 ">
            <header className="bg-white pt-3">
              <div className="h-full w-95 mx-auto flex justify-between items-center px-3 py-2 gap-3">
                <div className="flex gap-3 items-center">
                  <img
                    className="h-16 w-16"
                    src={sabccoLogo}
                    alt="SABCCO Logo"
                  />
                  <div>
                    <p className="text-center text-[14px] font-semibold">
                      SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
                      CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO) SONAM
                      TSHERING MARG, GANGTOK
                    </p>
                  </div>
                </div>
              </div>
            </header>
            <body className="pt-5 bg-white mx-4">
              <div className="pt-2 ">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CHEQUE RECEIPT
                </p>
                <div className="flex justify-between items-center pt-2 mx-4 ">
                  <p>Sl. No: {receiptDetails?.receipt_reference_number} </p>
                  <p>
                    Date:{" "}
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>

                <div className="mt-6 mx-4">
                  <p className="text-justify pt-4 ">
                    Received with thanks from Mr/Ms{" "}
                    <span className="font-semibold uppercase">
                      {loaneeData?.applicantname}
                    </span>{" "}
                    <span className="font-semibold">
                      {loaneeData?.applicantrelative}
                    </span>{" "}
                    <span className="uppercase font-semibold">
                      {loaneeData?.applicantrelativename}
                    </span>
                    , resident of{" "}
                    <span className="font-semibold uppercase">
                      {loaneeData?.permanentaddress}
                    </span>
                    . Loan ID No{" "}
                    <span className="font-semibold">
                      {loaneeData?.loan_number}
                    </span>
                    . A sum of (Rupees{" "}
                    <span className="font-semibold">
                      {formatINRCurrency(receiptDetails?.rp_amount)}
                    </span>{" "}
                    <span className="font-bold">
                      {number_in_words(receiptDetails?.rp_amount)}
                    </span>{" "}
                    only) on account of SABCCO loan by cheque/D.D. No{" "}
                    <span className="font-bold px-1">
                      {receiptDetails?.tr_cheque_dd_no}
                    </span>{" "}
                    date ………
                  </p>
                </div>
              </div>
            </body>
            <footer className="mt-20 mx-3">
              <div className="flex justify-end">
                <table className="text-sm font-semibold border-collapse border border-black">
                  <tbody>
                    <tr>
                      <td className="border border-black px-6">
                        Total amount due
                      </td>
                      <td className="border border-black px-20">
                        {formatINRCurrency(totalDueAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6">
                        Total amount received
                      </td>
                      <td className="border border-black px-20">
                        {formatINRCurrency(+total_paid_amt)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end items-center pt-12">
                <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
              </div>
              <div className="flex justify-start items-center ">
                <p>*Subject to realization of cheque cashier</p>
              </div>
            </footer>
          </Section>
        )} */}
      </>
    </>
  );
}
