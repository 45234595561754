import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function PenalInterestList({ penalList, ref }) {
  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200 text-sm my-3 sticky top-0 leading-4 "
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Default Amount
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Repayment
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Balance
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Period
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Days
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Int. Rate <br />
              <small>(Anually)</small>
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Penal
            </th>
            <th
              scope="col"
              className="px-6 py-1.5 text-left font-medium text-slate-900  tracking-wider "
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
          {penalList?.length > 0 ? (
            penalList?.map((penal, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-1 whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(penal?.pi_end_date)}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {formatINRCurrency(penal?.pi_default_amount) || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {formatINRCurrency(penal?.pi_repayment) || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {formatINRCurrency(penal?.pi_balance) || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  <div className="flex flex-col">
                    <span>
                      {getFormattedDateDDMMMYYYYDate(penal?.pi_start_date)}, To
                    </span>

                    <span>
                      {getFormattedDateDDMMMYYYYDate(penal?.pi_end_date)}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {penal?.pi_days || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {penal?.pi_int_rate_anually || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {formatINRCurrency(penal?.pi_penal_interest) || 0}
                </td>
                <td className="px-6 py-1 whitespace-nowrap">
                  {formatINRCurrency(penal?.pi_total) || 0}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      {/* <section className="p-3 bg-slate-100 text-sm my-3 grid grid-cols-2">
        <p>
          Total Interest Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalInterestAmount}
          </span>
        </p>
        <p>
          Total Interest Pay Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalIntPayAmount}
          </span>
        </p>
        <p>
          Total Penal Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalPenalAmount}
          </span>
        </p>
        <p>
          Total Penal Pay Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalPenalPayAmount}
          </span>
        </p>
        <p>
          Total IOI Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalIOIAmount}
          </span>
        </p>
        <p>
          Total IOI Pay Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalIOIPayAmount}
          </span>
        </p>
      </section> */}
    </>
  );
}
