import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../../authentication/hooks/useAuth";
import { REPAYMENT_CONFIG_URL } from "../../../../../api/api_routing_urls";
import showToast from "../../../../../utilities/notification/NotificationModal";
import FilterDropdown from "../../../../../reusable-components/search/FilterDropdown";
import { genderDD } from "../../../../../utilities/constants/constants";
import Dropdown from "../../../../../reusable-components/inputs/Dropdowns/Dropdown";

export default function ExcessAmtForm({
  setShowExcessAmtForm,
  addRepaymentDetails,
  getExcessAmtWithLoaneeId,
  // excessAmt,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTXNType, setSelectedTXNType] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: 0,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const handleFormSubmit = async (data) => {
    console.log("data", data);
    console.log("selectedTXNType", selectedTXNType);

    try {
      setIsLoading(true);
      let response = "";
      response = await axiosPrivate.post(
        `${REPAYMENT_CONFIG_URL}/addExcessAmt`,
        {
          loanee_id: +addRepaymentDetails?.loanee_id,
          payment_amount: +data?.excess_amt,
          txn_type: selectedTXNType?.value,
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Excess amount has been updated successfully", "success");
        getExcessAmtWithLoaneeId(addRepaymentDetails?.loanee_id);
      } else {
        showToast(
          "Whoops!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        setShowExcessAmtForm(false);
      }
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
      setShowExcessAmtForm(false);
    }
  };

  const txnTypeDD = [
    { value: "Credit", label: "Credit" },
    { value: "Debit", label: "Debit" },
  ];

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="grid grid-cols-3 gap-x-3 ">
          <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2">
            <legend className="px-2 ml-3 bg-slate-700 rounded-sm text-white text-sm p-1">
              Excess Amount Settlement
            </legend>
            <div className=" ">
              <Input
                defaultName="excess_amt"
                register={register}
                name="Excess Amount"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter amount"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="numeric"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={0}
                setValue={setValue}
              />
            </div>
            <Dropdown
              defaultName="txn_type"
              register={register}
              labelname="Transaction Type"
              required={false}
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={txnTypeDD}
              defaultValue={null}
              setValue={setValue}
              setSelected={setSelectedTXNType}
              selected={selectedTXNType}
              maxMenuHeight={120}
            />
          </fieldset>
        </div>
        <div className="flex justify-end gap-x-6 mt-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Submit"}
            className={button}
          />
          <Button
            onClick={() => {
              setShowExcessAmtForm(false);
            }}
            aria-label="Cancel Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}
