import React from "react";
import { CiCalendar } from "react-icons/ci";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import ReportCardToPrint from "./DailyReport-Component/ReportCardToPrint";
import { BsDot } from "react-icons/bs";
import ReportCard from "../ReportCard";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";

export default function DailyReportListPrint({
  dailyReportList,
  specificDate,
  selectedCategory,
  selectedDistrictName,
  selectedConstituencyName,
  selectedApplicantGender,
}) {
  return (
    <>
      <>
        <section
          className="mt-4 text-xs 
          grid grid-cols-1 md:grid-cols-4 gap-3 border p-3"
        >
          <div className=" md:col-span-4 text-slate-800 flex justify-between items-center text-xs">
            <div className="flex flex-wrap items-center gap-3 ">
              <div className="flex items-center gap-1">
                <CiCalendar size={19} />
                <span className="font-semibold">
                  {getFormattedDateDDMMMYYYYDate(specificDate)
                    ? getFormattedDateDDMMMYYYYDate(specificDate)
                    : "--  -- , --"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                Category:{" "}
                <span className="font-semibold ml-1 ">
                  {selectedCategory ? selectedCategory?.label : "N/A"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                District:{" "}
                <span className="font-semibold ml-1">
                  {selectedDistrictName ? selectedDistrictName?.label : "N/A"}
                </span>
              </div>
              <BsDot size={20} />
              <div>
                Constituency:{" "}
                <span className="font-semibold ml-1">
                  {selectedConstituencyName
                    ? selectedConstituencyName?.label
                    : "N/A"}
                </span>
              </div>
              <BsDot size={20} />
              <div>
                Gender:{" "}
                <span className="font-semibold ml-1">
                  {selectedApplicantGender
                    ? selectedApplicantGender?.label
                    : "N/A"}
                </span>
              </div>
            </div>

            <div className="md:justify end">
              No. of Repayments:{" "}
              <span className="font-semibold ml-1">
                {dailyReportList?.daily_report?.length || 0}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-4 justify-evenly">
            <ReportCard
              title={"Cash"}
              principle={dailyReportList?.repay_bal_cash_amt}
              interest={dailyReportList?.repay_int_cash_amt}
              penal_Interest={dailyReportList?.repay_pi_cash_amt}
              interest_on_interest={dailyReportList?.repay_ioi_cash_amt}
              ots_repayment={dailyReportList?.repay_ots_cash_amt}
            />

            <ReportCard
              title={"Cheque"}
              principle={dailyReportList?.repay_bal_cheque_amt}
              interest={dailyReportList?.repay_int_cheque_amt}
              penal_Interest={dailyReportList?.repay_pi_cheque_amt}
              interest_on_interest={dailyReportList?.repay_ioi_cheque_amt}
              ots_repayment={dailyReportList?.repay_ots_cheque_amt}
            />

            <ReportCard
              title={"Branch"}
              principle={dailyReportList?.repay_bal_branch_amt}
              interest={dailyReportList?.repay_int_branch_amt}
              penal_Interest={dailyReportList?.repay_pi_branch_amt}
              interest_on_interest={dailyReportList?.repay_ioi_branch_amt}
              ots_repayment={dailyReportList?.repay_ots_branch_amt}
            />
            <ReportCard
              title={"Total"}
              principle={
                +dailyReportList?.repay_bal_cash_amt +
                +dailyReportList?.repay_bal_cheque_amt +
                +dailyReportList?.repay_bal_branch_amt
              }
              interest={
                +dailyReportList?.repay_int_cash_amt +
                +dailyReportList?.repay_int_cheque_amt +
                +dailyReportList?.repay_int_branch_amt
              }
              penal_Interest={
                +dailyReportList?.repay_pi_cash_amt +
                +dailyReportList?.repay_pi_cheque_amt +
                +dailyReportList?.repay_pi_branch_amt
              }
              interest_on_interest={
                +dailyReportList?.repay_ioi_cash_amt +
                +dailyReportList?.repay_ioi_cheque_amt +
                +dailyReportList?.repay_ioi_branch_amt
              }
              ots_repayment={
                +dailyReportList?.repay_ots_cash_amt +
                +dailyReportList?.repay_ots_cheque_amt +
                +dailyReportList?.repay_ots_branch_amt
              }
            />
          </div>
        </section>
      </>

      <table className="my-10  border-2 border-slate-300 ">
        <thead className="bg-slate-200 text-xs leading-5 sticky top-0  ">
          <tr className="text-xs border ">
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              SI No
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            {/* <th
      scope="col"
      className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
    >
      Loan No
    </th> */}
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Payment Mode
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Interest Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              P.I Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              I.O.I Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              OTS Paid
            </th>
          </tr>
        </thead>

        {/* {dailyReportList?.daily_report?.map((dailyObj, key) => (
    <p>{dailyObj?.applicantname}</p>
  ))} */}

        <tbody className="bg-white divide-y text-xs divide-slate-100 text-slate-600   ">
          {dailyReportList?.daily_report?.length > 0 ? (
            dailyReportList?.daily_report?.map((dailyObj, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj.loanee_id}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.applicantname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.pay_mode}
                </td>

                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.tr_amount}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.int_credit}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.int_picredit}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.interest_on_interest_pay}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {dailyObj?.ots_tr_amount}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
