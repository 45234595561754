import React, { useRef, useState } from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import { useForm } from "react-hook-form";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { full_width_button } from "../../../theme/lightTheme";
import { contactnoPattern } from "../../../utilities/generalFunctions/regexPatterns";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ApplicantLogin() {
  const location = useLocation();
  const navigate = useNavigate();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    console.log("data", data);

    let applicantDetails = {
      phoneNo: data?.phone_number,
      serialNo: uniqueSerialNumber,
    };
    try {
      // navigate(`/noc-applicant-veriffy-otp`);
      navigate("/noc-applicant-veriffy-otp", {
        state: { applicantDetails },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { uniqueSerialNumber } = location.state || {};

  console.log("uniqueSerialNumber", uniqueSerialNumber);

  return (
    <div>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Applicant Login</H1>
          </div>
        </div>
      </HeroBanner>

      <section className="my-10 min-h-screen">
        <div className=" flex flex-col justify-start items-center  ">
          <div className="w-11/12 md:w-4/6 lg:w-2/4 xl:w-2/6 2xl:w-2/6 bg-white border p-4 ">
            <div className="text-center pb-4">
              {/* <p className="text-xl font-medium text-primary ">
                  Lonee Repayment Details
                </p> */}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} id="repaymentLoginForm">
              <p
                ref={errRef}
                className={
                  errMsg ? "px-2 py-1 bg-red-100 text-red-800" : "hidden"
                }
                aria-live="assertive"
              >
                {errMsg}
              </p>

              <Input
                defaultName="phone_number"
                register={register}
                name="Phone Number"
                required={false}
                pattern={contactnoPattern}
                errors={errors}
                placeholder="Enter your phone number"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-[100%] `}
                onChangeInput={null}
                defaultValue={null}
                setValue={setValue}
              />

              <div className="pt-6">
                <SubmitButton
                  isSubmitting={isSubmitting}
                  label="Proceed"
                  className={`${full_width_button} `}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
