/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  NOTICE_URL,
  GET_PENAL_INTEREST_DETAILS,
  REPAYMENT_CONFIG_URL,
  REPORTS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../authentication/hooks/useAuth";
import { useState } from "react";
import showToast from "../../../utilities/notification/NotificationModal";
import { useEffect } from "react";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdRemoveRedEye } from "react-icons/md";
import AddButton from "../../../reusable-components/buttons/AddButton";
import { BiChevronLeft } from "react-icons/bi";

import { Paging } from "../../../reusable-components/paging/Paging";
import DemandNoitice_List from "./DemandNotice_List";
import ViewDemand_Notice from "./ViewDemand_Notice";
import { getFormattedDateInputDate } from "../../../utilities/dateFunctions/formatdate";
import { useMemo } from "react";
import GenerateDemandNotice from "./GenerateDemandNotice";
import { BsDot } from "react-icons/bs";
import { GET_INTEREST_ON_INTEREST_DETAILS } from "../../../api/api_routing_urls";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";

export default function DemandNoticeListConfig({
  loaneeId, // you can  also get this details from loanee Details just to send individually i use it
  setShowDemandNoticeList,
  loanNumber, //  you can also get this details from loanee Details just to send individually i use it
  loaneeDetails, // you can get loaneeId AND loan Number form this props also
}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [demandNoticetList, setDemandNoticeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [showNotice, setShowNotice] = useState(false);
  const [loaneReferenceNumberDetails, setLoaneeReferenceNumberDetails] =
    useState();

  const demandNoticeDetails = demandNoticetList[0]?.get_notice_list;

  const [principleDueWithIntData, setPrincipleDueWithIntData] = useState();
  const [maximumPayAmount, setMaximumPayAmount] = useState();
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [penalInterestList, setPenalInterestList] = useState([]);
  const [interestOnInterestList, setInterestOnInterestList] = useState([]);
  const [ioiTotalDue, setIoiTotalDue] = useState();

  const [specificDate, setSpecficDate] = useState();

  const [guarantorNamesDD, setGuarantorNamesDD] = useState();
  const [guarantorListForNotice, setGuarantorListForNotice] = useState();
  const [selectedGuarantor, setSelectedGuarantor] = useState();

  const [showFinalNotice, setShowFinalNotice] = useState(false);

  const [showGenerateDemandNotice, setShowGenerateDemandNotice] =
    useState(false);

  const [penalInterest_Amount, setPenalInterest_Amount] = useState();

  const selectedDate = loaneReferenceNumberDetails?.generated_date;

  // const lastIndex =
  //   penalInterestList?.length > 0 ? penalInterestList?.length - 1 : 0;

  // const PenalInterest_Amount =
  //   penalInterestList[lastIndex]?.penal_due_amount || 0;

  const [noticeType, setNoticeType] = useState("Demand Notice");

  const getDemandNoticeList = async (page_no, page_size) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${NOTICE_URL}`,
        {
          params: {
            loanee_id: loaneeId || 1,
            pagenumber: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            notice_type: noticeType,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (
        response.status === 200 &&
        response?.data?.noticeList[0]?.get_notice_list !== null &&
        response?.data?.noticeList[0]?.get_notice_list !== undefined
      ) {
        setDemandNoticeList(response?.data?.noticeList);

        const totalNumberOfApplicant =
          response?.data?.noticeList[0]?.get_notice_list[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Demand list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getPrincipleDueWithInterestUntilDate = async (specfic_date) => {
    try {
      setIsLoading(true);
      if (specfic_date) {
        const response = await axiosPrivate.get(
          `${REPAYMENT_CONFIG_URL}/principleDueWithInterestUntilDate`,
          {
            params: {
              loan_number: loanNumber,
              specficdate: specfic_date,
            },
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status === 200) {
          let last_repayment_date = getFormattedDateInputDate(
            response?.data?.principleDueWithInterestData?.last_repaymentdate
          );

          if (last_repayment_date <= getFormattedDateInputDate(specfic_date)) {
            setPrincipleDueWithIntData(
              response?.data?.principleDueWithInterestData
            );
            setTotalDueAmount(
              +response?.data?.principleDueWithInterestData
                ?.interest_due_amount +
                +response?.data?.principleDueWithInterestData?.principle_due
            );
            setMaximumPayAmount(
              +response?.data?.principleDueWithInterestData
                ?.interest_due_amount +
                +response?.data?.principleDueWithInterestData
                  ?.principle_outstanding_amount
            );
          } else {
            // getPrincipleDueWithInterestUntilDate(last_repayment_date);
            // repaymentDateChangeHandler(last_repayment_date);
          }

          response.status === 202 &&
            showToast("No state list found in the system", "error");
        }
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPenalInterestDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_PENAL_INTEREST_DETAILS,
        {
          params: {
            loan_number: loanNumber,
          },
        },
        {
          signal: controller.signal,
        }
      );

      // console.log(
      //   "response to get penal interst",
      //   response?.data?.ledgerDetails?.penal_due_amount
      // );

      if (response.data) {
        // setIsLoading(false);
        // setPenalInterestList(
        //   response?.data?.ledgerDetails?.pi_ledger_list || []
        // );

        setPenalInterest_Amount(
          response?.data?.ledgerDetails?.penal_due_amount
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInterestOnInterestDetail = async () => {
    setIsLoading(true);
    let ioiValue = undefined;

    try {
      let response = await axiosPrivate.get(
        GET_INTEREST_ON_INTEREST_DETAILS,
        {
          params: {
            loan_number: loanNumber || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setInterestOnInterestList(
          response?.data?.ledgerDetails?.ioi_ledger_list
        );
        ioiValue = +response?.data?.ledgerDetails?.ioi_due_amount;

        setIoiTotalDue(ioiValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getGuarantorListForNotice = async () => {
    try {
      setIsLoading(true);

      let response = await axiosPrivate.get(
        `${NOTICE_URL}/getGuarantorListForNotice`,
        {
          params: {
            loanee_id: loaneeDetails?.loanee_id || 1,
          },
        },
        {
          signal: controller.signal,
        }
      );

      let guarantor_NamesDD = [];

      if (response?.status === 200) {
        response?.data?.guarantorListForNotice?.map((guarantorDetailsObj) => {
          guarantorDetailsObj?.get_guarantor_list_for_notice?.map(
            (guarantorNamesobj) => {
              guarantor_NamesDD.push({
                label: guarantorNamesobj?.guarantorname,
                value: guarantorNamesobj?.guarantor_id,
                address: guarantorNamesobj?.guarantoraddress,
              });
            }
          );
        });
        setGuarantorNamesDD(guarantor_NamesDD);
      }
      {
        setGuarantorListForNotice(response?.data?.guarantorListForNotice[0]);
      }
      response.status === 202 &&
        showToast("No Direct Departmental list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getDemandNoticeList(pageNo, pageSize?.value);
  }, [loaneeId, pageNo, pageSize?.value]);

  useMemo(() => {
    if (selectedDate) {
      getPrincipleDueWithInterestUntilDate(selectedDate);
    }
  }, [selectedDate]);

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  useEffect(() => {
    getDemandNoticeList(pageNo, pageSize?.value);
  }, [pageNo, pageSize?.value]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getInterestOnInterestDetail();
      getPenalInterestDetail();
      getGuarantorListForNotice();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (showFinalNotice == true) {
      setNoticeType("Final Notice");
    } else {
      setNoticeType("Demand Notice");
    }
  }, []);

  return (
    <>
      {showGenerateDemandNotice ? (
        <>
          <GenerateDemandNotice
            setShowGenerateDemandNotice={setShowGenerateDemandNotice}
            onChange={(e) => setSpecficDate(e)}
            totalDueAmount={totalDueAmount}
            principleDueWithIntData={principleDueWithIntData}
            loaneeDetails={loaneeDetails}
            getPrincipleDueWithInterestUntilDate={
              getPrincipleDueWithInterestUntilDate
            }
            loaneReferenceNumberDetails={loaneReferenceNumberDetails}
            setLoaneeReferenceNumberDetails={setLoaneeReferenceNumberDetails}
            getDemandNoticeList={getDemandNoticeList}
            penalInterest_Amount={penalInterest_Amount}
            ioiTotalDue={ioiTotalDue}
            isLoading={isLoading}
            showFinalNotice={showFinalNotice}
            selectedGuarantor={selectedGuarantor}
            setSelectedGuarantor={setSelectedGuarantor}
          />
        </>
      ) : (
        <>
          {showNotice ? (
            <ViewDemand_Notice
              setShowNotice={setShowNotice}
              loaneReferenceNumberDetails={loaneReferenceNumberDetails}
              loanNumber={loanNumber}
              totalDueAmount={totalDueAmount}
              principleDueWithIntData={principleDueWithIntData}
              loaneeDetails={loaneeDetails}
              penalInterest_Amount={penalInterest_Amount}
              ioiTotalDue={ioiTotalDue}
              showFinalNotice={showFinalNotice}
              guarantorNamesDD={guarantorNamesDD}
              selectedGuarantor={selectedGuarantor}
              setSelectedGuarantor={setSelectedGuarantor}
            />
          ) : (
            <>
              <div className="flex justify-between flex-wrap">
                <H2withIcon>
                  <MdAssignmentTurnedIn />
                  <span className="pl-1.5">Demand Notice Details</span>
                </H2withIcon>

                <div className="flex items-center flex-wrap ">
                  <div className=" mr-3 ">
                    {loaneeDetails?.loan_status === "Active" ? (
                      <div className="flex gap-x-4 flex-wrap">
                        <AddButton
                          label="Generate Final Notice"
                          onclick={() => {
                            setShowGenerateDemandNotice(true);
                            setLoaneeReferenceNumberDetails(null);
                            setShowFinalNotice(true);
                          }}
                        />

                        <AddButton
                          label="Generate Demand Notice"
                          onclick={() => {
                            setShowGenerateDemandNotice(true);
                            setLoaneeReferenceNumberDetails(null);
                            setShowFinalNotice(false);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="flex items-center cursor-pointer hover:underline underline-offset-2 py-3 md:mb-4"
                    onClick={() => {
                      setLoaneeReferenceNumberDetails(null);
                      setShowDemandNoticeList(false);
                    }}
                  >
                    <BiChevronLeft size={24} /> back to list
                  </div>
                </div>
              </div>

              <div className="my-4">
                <div className=" text-sm flex items-center flex-wrap gap-1">
                  <div>
                    Loan Id:{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.loanee_id}
                    </span>
                  </div>
                  <BsDot size={14} />
                  <div>
                    Loan Number:{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.loan_number}
                    </span>
                  </div>
                  <BsDot size={14} />
                  <div>
                    Loanee Name:{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.applicantname}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <DemandNoitice_List
                  setShowNotice={setShowNotice}
                  demandNoticeDetails={demandNoticeDetails}
                  setLoaneeReferenceNumberDetails={
                    setLoaneeReferenceNumberDetails
                  }
                  getDemandNoticeList={getDemandNoticeList}
                  showFinalNotice={showFinalNotice}
                  setShowFinalNotice={setShowFinalNotice}
                />

                <Paging
                  maxMenuHeight={100}
                  selected={pageSize}
                  setSelected={setPageSize}
                  pageNo={pageNo}
                  onPageChangeHandler={onPageChangeHandler}
                  numberOfPages={numberOfPages}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
