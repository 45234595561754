import React from "react";

import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { CSVLink } from "react-csv";
import { getFormattedDateDDMMYYYY } from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

export default function LoanOutstandingLedgerList({
  loanOutstandingLedgerList,
  ref,
}) {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200 sticky text-sm top-0 pt-3 leading-5"
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loanee Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Gender
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Scheme Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              District
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Last Repayment Date
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Disbursement Amount
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Due
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              IOI Due
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Penal Due
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Interest Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              I.O.I Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              P.I Paid
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600 text-xs">
          {loanOutstandingLedgerList?.length > 0 ? (
            loanOutstandingLedgerList?.map((sanctionList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.loanee_id}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.applicantname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.categoryname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.applicantgender}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.schemename || "NA"}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {sanctionList?.districtname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {getFormattedDateDDMMYYYY(sanctionList?.last_repaymentdate) ||
                    "NA"}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.di_amount || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.principle_due || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.ioi_due || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.penal_due || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.rp_amount || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.int_repay || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.ioi_repay || 0)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatINRCurrency(sanctionList?.penal_repay || 0)}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={loanOutstandingLedgerList}
          target="_blank"
          filename={`SABCCO-LoanOutstandingLedgers-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}
