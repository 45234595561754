import React from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { useState } from "react";

export default function OTSNoticeTemplate({
  selectedDate,
  refNumber,
  loaneeDetails,
  otsLoaneeDetails,
  currentFiscalYear,
  daysAheadToSelectedDate,
  otsLoaneeDue,
  selectedGuarantor,
}) {
  let totalOutstandingDues =
    +otsLoaneeDetails?.ioi_due +
    +otsLoaneeDetails?.int_due +
    +otsLoaneeDetails?.penal_due +
    +otsLoaneeDetails?.principleoutstanding;

  return (
    <section className="my-6  text-[12px]">
      <div className="flex justify-between items-center">
        <span>Ref No: {refNumber}</span>
        <span>Dated: {selectedDate}</span>
      </div>
      <div className="">
        <p className="underline font-semibold text-center">
          "In-principle OTS Approval"
        </p>
        <p className="text-center ">"Without Prejudice"</p>
      </div>
      <div className="py-2">
        <p>To,</p>
        <p>{loaneeDetails?.applicantname}</p>
        <p className="uppercase">
          {loaneeDetails?.applicantrelative === "N/A" ? (
            <></>
          ) : (
            <>{loaneeDetails?.applicantrelative}:&nbsp;</>
          )}

          {loaneeDetails?.applicantrelativename === "N/A" ? (
            <></>
          ) : (
            <>{loaneeDetails?.applicantrelativename}</>
          )}
        </p>
        <p className="uppercase">{loaneeDetails?.permanentaddress}</p>
      </div>
      <div>
        <p className="py-2 font-bold underline underline-offset-2">
          Subject: One Time Settlement (OTS) Scheme
          {/* Your{" "}
          <span className="font-semibold">{loaneeDetails?.schemename}</span>
          &nbsp; Loan having loan ID no{" "}
          <span className="font-semibold">{loaneeDetails?.loanee_id}</span> with
          SABCCO having outstanding balance of{" "}
          <span className="font-semibold">
            {formatINRCurrency(otsLoaneeDetails?.principleoutstanding)}
          </span>{" "}
          as on{" "}
          <span className="font-semibold">
            {loaneeDetails?.loan_first_disbursment_date}
          </span> */}
        </p>
      </div>
      <p className="py-2">Sir/Madam,</p>
      <div className="py-2">
        We are pleased to inform you that this Corporation has come out with a
        'One Time Settlement Scheme' &nbsp;
        <span className="font-semibold">{currentFiscalYear}</span> for the loan
        availed prior to <span className="font-semibold">31.03.2010</span>. Your
        loan account is also eligible under this Scheme whereby leaving the
        Principle loan amount intact, there will be waiver of{" "}
        <span className="font-semibold">
          {otsLoaneeDetails?.waiver_percentage}%&nbsp;
        </span>
        in the outstanding interest component under this full and final loan
        settlement offer.
      </div>
      <div className="py-2">
        You are advised to visit this office at the earliest possible to avail
        this opportunity. The above offer is valid till{" "}
        <span className="font-semibold">31.03.2025</span> only.
        <p className="py-2">
          Your outstanding dues as on{" "}
          <span className="font-semibold">{selectedDate}</span> is as under
        </p>
      </div>
      <div className="py-2">
        <ul className="grid grid-cols-4">
          <div className="py-2">
            <li>Principle Outstanding due : </li>
            <li>Simple Interest due :</li>
            <li>Penal Interest :</li>
            <li className="underline underline-offset-4">
              Interest on Interest :
            </li>
            <li className="pt-1">Total:</li>
            <li className="pt-6">
              Substantial concession of 20% in interest accrued :
            </li>
            <li className="pt-2 font-semibold">Net Payable :</li>
          </div>

          <div className="font-semibold py-2">
            <li>{formatINRCurrency(otsLoaneeDetails?.principleoutstanding)}</li>
            <li>{formatINRCurrency(otsLoaneeDetails?.int_due)}</li>
            <li>{formatINRCurrency(otsLoaneeDetails?.penal_due)}</li>
            <li className="underline underline-offset-4">
              {formatINRCurrency(otsLoaneeDetails?.ioi_due)}
            </li>
            <li className="pt-1"> {formatINRCurrency(totalOutstandingDues)}</li>
            <li className="pt-6">
              {" "}
              {formatINRCurrency(otsLoaneeDetails?.waiveramount)}
            </li>
            <li className="pt-6">
              {formatINRCurrency(otsLoaneeDue?.topay_amt)}
            </li>
          </div>
        </ul>
      </div>

      <div>
        Further, you can also repay/deposit your installment in any nearest
        Branch of SISCO Bank providing your name and Loan ID number. Your loan
        ID number is{" "}
        <span className="font-semibold">{loaneeDetails?.loanee_id}</span>
        <p className="font-semibold py-3">
          This offer will stand automatically lapsed upon failure to act at your
          end within the stipulated time period and SABCCO have the right to
          recover full dues with up to date interest thereafter.
        </p>
      </div>
      <div className="flex justify-between">
        <div>
          <p className="py-3">Thanking you,</p>

          {selectedGuarantor ? (
            <>
              <p>Copy To </p>
              <p>
                Guarantor Name:
                <span className="font-semibold">
                  {selectedGuarantor?.label}
                </span>
              </p>
              <p>
                Guarantor Address:
                <span className="font-semibold">
                  {selectedGuarantor?.address}
                </span>
              </p>
            </>
          ) : null}
        </div>

        <div>
          <p className="pb-6">Yours faithfully,</p>
          <p>Dy. General Manager/Assistant Manager</p>
          <p>SABCCO</p>
        </div>
      </div>
    </section>
  );
}
