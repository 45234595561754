/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaHome } from "react-icons/fa";
import sabcco_logo from "../../../../assets/SABCCO_128x128.png";
import { useSelector } from "react-redux";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { Link, useNavigate } from "react-router-dom";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../../reusable-components/headings/H1";
import axios from "../../../../api/axios";
import {
  MANAGE_OTP_DETAILS_URL,
  RAS_KEY_URL,
} from "../../../../api/api_routing_urls";
import rsa from "../../../../utilities/rsaFunction/rsaEncryption";

import { useDispatch } from "react-redux";
import { setCompleteLoaneeDetails } from "../../../../features/loaneeDetails/LoaneeDetails";

const { rsaEncrypt } = rsa;

export default function VerifyOtpPage() {
  const { completeLoaneeDetails } = useSelector((state) => state.loaneeDetails);

  const [showOtpSentText, setShowOtpSentText] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isVerifyingText, setIsVerifyingText] = useState(false);
  const [verificationFailStatus, setVerificationFailStatus] = useState(false);
  const [rsaPublicKey, setRsaPublicKey] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const defaultValues = {
    phone_number: "",
    session_otp: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RAS_KEY_URL);
      if (response?.data?.status === 201) {
        console.log("RAS_KEY_URL", response?.data?.rsa_detail);

        return response?.data?.rsa_detail;
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmitVerify = async (data) => {
    console.log("data", data);
    setIsVerifyingText(true);
    const { publicKey, session_uuid } = await getPublicKeyRsa();
    console.log("publicKey", publicKey);

    // try {
    //   const test_otp = rsaEncrypt("1234", publicKey);
    //   console.log({ test_otp });
    // } catch (error) {
    //   console.error(error);
    // }

    if (publicKey !== undefined && publicKey !== null && publicKey !== "") {
      const encryptedOtp = rsaEncrypt(data?.session_otp, publicKey);

      try {
        let sendDataObj = {
          phone_number: `${completeLoaneeDetails?.contact_number}`,
          session_otp: encryptedOtp,
          session_uuid: session_uuid,
        };

        let response = await axios.post(
          `${MANAGE_OTP_DETAILS_URL}/verifyOtpRepaymentOnline`,
          sendDataObj,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        console.log(
          "response to check accessToken",
          response?.data?.accessToken
        );

        if (response.status === 200) {
          setVerificationFailStatus(0);

          //we get acessToken in response

          dispatch(
            setCompleteLoaneeDetails({
              ...completeLoaneeDetails,
              accessToken: response?.data?.accessToken,
            })
          );

          // dispatch(
          //   setCompleteLoaneeDetails(prev =>  {
          //     ...prev,
          //     accessToken: response.accessToken,
          //   })

          navigate("/online-repayment-details");
        } else {
          console.log("OTP entered is wrong.", "error");
          setVerificationFailStatus(1);
          if (response.data.userdetails.includes("unique_user_phone_number")) {
            console.log("This entry already exists.", "error");
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
          return;
        }
        reset();
      } catch (error) {
        // ... (existing catch block remains unchanged)
      } finally {
        setIsVerifyingText(false);
      }
    } else {
      console.error(
        "Encryption error in login due to absence of rsaPublicKey!"
      );
    }
  };

  // console.log(
  //   "completeLoaneeDetails after adding the accessToken",
  //   completeLoaneeDetails
  // );

  const onSubmitOtpClick = async () => {
    console.log("onSubmitOtpClick");
    setShowOtpSentText(true);
    try {
      let sendDataObj = {
        phone_number: String(completeLoaneeDetails?.contact_number),
        // phone_number: "7872279905",
      };

      let response = "";
      response = await axios.post(`${MANAGE_OTP_DETAILS_URL}`, sendDataObj);
      console.log("response", response);
      if (response?.status === 200) {
        setShowOtpInput(true);
      } else {
        console.log("Phone Number could not be verified. Please try again.");
        if (response.data.userdetails.includes("unique_phone_number")) {
          console.log("This entry already exists.");
        } else {
          console.log(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
            "error"
          );
        }
        return;
      }
      reset();
    } catch (error) {
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  console.log("showOtpInput", showOtpInput);

  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>OTP Verification</H1>
          </div>
        </div>
      </HeroBanner>
      <section className="md:w-[40%] mx-auto min-h-screen ">
        <div className="border py-5">
          <div className="flex justify-center">
            <img src={sabcco_logo} className="w-20" />
          </div>
          <div className="mt-10 flex flex-col justify-center">
            <div className="text-center text-primary text-xl font-semibold">
              Verification
            </div>
            <div className="mt-2 text-center text-sm text-gray-500">
              You will get an OTP via SMS on this number:
              <span className="font-semibold text-black">
                {completeLoaneeDetails?.contact_number}
              </span>
            </div>
          </div>
          {!showOtpSentText ? (
            <div className="flex justify-center mt-5">
              <div
                onClick={() => onSubmitOtpClick()}
                className="px-4 py-1 max-w-[65%] text-white text-xs cursor-pointer mb-10 border bg-primary rounded-sm"
              >
                Send OTP
              </div>
            </div>
          ) : (
            <p className="flex justify-center text-primary text-xs my-3">
              OTP has been sent. Enter it below to proceed.
            </p>
          )}
          {verificationFailStatus === 1 && (
            <div className="flex justify-center text-red-600 text-xs pb-3">
              Seems like you have entered incorrect OTP. Please try again.
            </div>
          )}
          {showOtpInput && (
            <>
              {/* form section start*/}
              <section className="mt-8 mx-auto max-w-[65%]">
                <form onSubmit={handleSubmit(onSubmitVerify)}>
                  <div>
                    <Input
                      defaultName="session_otp"
                      register={register}
                      name="One Time Password"
                      required={true}
                      pattern={null}
                      errors={errors}
                      placeholder="Enter the OTP you received"
                      setError={setError}
                      clearError={clearErrors}
                      autoComplete="off"
                      type="text"
                      classes={`rounded-md px-3 py-2 text-sm w-full`}
                      onChangeInput={null}
                      // defaultValue={defaultValues.user_password}
                      setValue={setValue}
                    />
                  </div>
                  <div className="mt-10 flex flex-col flex-grow">
                    <button
                      onClick={() => handleSubmit()}
                      className="bg-primary text-white text-xs px-3 py-1 lg:px-5 lg:py-2 uppercase"
                    >
                      {!isVerifyingText ? (
                        <div className="flex gap-x-1 justify-center items-center">
                          Verify
                        </div>
                      ) : (
                        <div className="flex gap-x-1 justify-center items-center">
                          <p>Verifying...</p>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </section>
              {/* form section end*/}
            </>
          )}
          <div className="mt-10 flex justify-center gap-x-1 text-primary">
            <div>
              <FaHome />
            </div>
            <Link to="/">
              <div className="text-xs mb-5">back to website</div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
