/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useState } from "react";
import { REPORTS_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import useAuth from "../../../../authentication/hooks/useAuth";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdInsertChartOutlined } from "react-icons/md";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import Button from "../../../../reusable-components/buttons/Button";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { PrintReport } from "../PrintReport/PrintReport";
import RecoveryReport_Print from "./RecoveryReport_Print";
import RecoveryCard from "./RecoveryCard";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";

const RecoveryConfig = () => {
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedStartYear, setSelectedStartlYear] = useState(null);
  const [selectedEndYear, setSelectedEndlYear] = useState(null);

  const [activeBorrower, setActiveBorrower] = useState(0);
  const [totalDisbursed, setTotalDisbursed] = useState(0);
  const [paymentReceived, setPaymentReceived] = useState(0);
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [totalPrincipleDue, setTotalPrincipleDue] = useState(0);
  const [totalPrincipleOutstanding, setTotalPrincipleOutstanding] = useState(0);
  const [totalInterestDue, setTotalInterestDue] = useState(0);
  const [endYearDD, setEndYearDD] = useState();

  const [ioiDue, setIoiDue] = useState(0);
  const [penalDue, setPenalDue] = useState(0);

  const [recoveryDetails, setRecoveryDetails] = useState([]);
  const [showPrint, setShowPrint] = useState(false);

  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: +currentYear + 1 - 1996 }, (_, index) => ({
    value: `${1996 + index}-04-01`,
    label: 1996 + index,
  }));

  // Category Wise Dashboard Report

  const getYearlyCompiledReport = async () => {
    try {
      setIsLoading(true);

      const response = await axiosPrivate.get(
        `${REPORTS_CONFIG_URL}/getRecoveryPercentage`,
        {
          params: {
            startdate: selectedStartYear?.value || `${currentYear - 1}-04-01`,
            enddate: selectedEndYear?.value || `${currentYear}-04-01`,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setRecoveryDetails(response?.data?.report);
        getDashboardReport(response?.data?.report);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowPrint(true);
    }
  };

  const getDashboardReport = (details) => {
    let active_Borrower = 0;
    let total_Disbursed = 0;
    let total_Repay_Amount = 0;
    let total_Due = 0;
    let total_principle_Due = 0;
    let total_interest_Due = 0;
    let total_principle_outstanding = 0;

    let total_ioi_due = 0;
    let total_penal_due = 0;

    details?.map((obj) => {
      active_Borrower += +obj.borrower;
      total_Disbursed += +obj.di_amount;
      total_Repay_Amount += +obj.rp_amount;
      total_Due += +obj.due_amount;
      total_principle_Due += +obj.principle_due;
      total_principle_outstanding += +obj.principle_outstanding;
      total_interest_Due += +obj.int_due_amount;

      total_ioi_due += +obj.ioi_due;
      total_penal_due += +obj.penal_due;
    });

    setActiveBorrower(active_Borrower);
    setTotalDisbursed(total_Disbursed);
    setPaymentReceived(total_Repay_Amount);
    setTotalDueAmount(total_Due);
    setTotalPrincipleDue(total_principle_Due);
    setTotalInterestDue(total_interest_Due);
    setTotalPrincipleOutstanding(total_principle_outstanding);

    setIoiDue(total_ioi_due);
    setPenalDue(total_penal_due);
  };

  useMemo(() => {
    setShowPrint(false);
    setTotalPrincipleDue(totalPrincipleDue);
    setTotalInterestDue(totalInterestDue);
  }, [selectedStartYear]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getYearlyCompiledReport();
      setShowPrint(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedStartYear?.value) {
      setSelectedEndlYear(null);
      let endYearList = [...years];

      endYearList = endYearList.filter(
        (obj) => obj?.value > selectedStartYear?.value
      );

      setEndYearDD(endYearList);
    }
  }, [selectedStartYear]);

  return (
    <>
      <Dashboard sidebarType={auth?.rolename}>
        {isLoading ? (
          <>
            <LoadingModal message={"Loading"} />
          </>
        ) : (
          <>
            <div className="flex items-center justify-between gap-2">
              <H2withIcon>
                <MdAssignmentTurnedIn />{" "}
                <span className="pl-1.5">Recovery Percentage Report</span>
              </H2withIcon>
            </div>
            <>
              <section className="flex justify-between gap-3 items-end mb-2 ">
                <div className="flex justify-between gap-3 w-[30%]">
                  <FilterDropdown
                    defaultName="start_year"
                    required={true}
                    classes="text-sm w-full"
                    placeholder="Start Year..."
                    data={years}
                    defaultValue={selectedStartYear}
                    setSelected={setSelectedStartlYear}
                    selected={selectedStartYear}
                    maxMenuHeight={256}
                  />
                  <FilterDropdown
                    defaultName="end_year"
                    required={true}
                    classes="text-sm w-full"
                    placeholder="Till Year..."
                    data={endYearDD}
                    defaultValue={selectedEndYear}
                    setSelected={setSelectedEndlYear}
                    selected={selectedEndYear}
                    maxMenuHeight={256}
                  />
                </div>

                <div className="flex gap-2">
                  <Button
                    className={
                      "bg-primary text-white text-sm md:px-6 py-2 min-w-36 flex items-center justify-center gap-1 rounded-sm"
                    }
                    onClick={() => getYearlyCompiledReport()}
                  >
                    <span className="mr-1">Generate Report</span>
                    <MdInsertChartOutlined size={18} />
                  </Button>
                </div>
              </section>
            </>

            <section className="mt-9">
              <div className="text-yellow-700 text-sm p-1">
                * The recovery report specifically focuses on borrowers who
                received loans between selected fiscal year.
              </div>
              <div className="border border-slate-300 p-6">
                <div
                  className="text-lg md:text-xl text-primary font-semibold border bg-slate-100 p-1 
                grid grid-cols-2"
                >
                  <div className="pl-3">All Category:</div>
                  <div>
                    {" "}
                    {(
                      (paymentReceived /
                        (paymentReceived +
                          totalPrincipleDue +
                          totalInterestDue +
                          ioiDue +
                          penalDue)) *
                      100
                    ).toFixed(2)}
                    %
                  </div>
                </div>
                <div className=" bg-slate-50  text-lg grid grid-cols-2 gap-x-3 md:gap-x-6 pt-6">
                  <div className="grid grid-cols-2 items-center">
                    Payment Received:
                    <span className="font-bold">
                      {" "}
                      {formatINRCurrency(paymentReceived)}
                    </span>
                  </div>

                  <div className="grid grid-cols-2 items-center">
                    Principle Due:
                    <span className="font-bold">
                      {" "}
                      {formatINRCurrency(totalPrincipleDue)}
                    </span>
                  </div>
                  <div className="grid grid-cols-2 items-center">
                    Interest Due Amount:
                    <span className="font-bold">
                      {" "}
                      {formatINRCurrency(totalInterestDue)}
                    </span>
                  </div>
                  {ioiDue ? (
                    <div className="grid grid-cols-2 items-center">
                      Interest on Interest Due :
                      <span className="font-bold">
                        {" "}
                        {formatINRCurrency(ioiDue)}
                      </span>
                    </div>
                  ) : null}
                  {penalDue ? (
                    <div className="grid grid-cols-2 items-center">
                      Penal Interest Due :
                      <span className="font-bold">
                        {" "}
                        {formatINRCurrency(penalDue)}
                      </span>
                    </div>
                  ) : null}
                  <div className="grid grid-cols-2 items-center">
                    Principle Due:
                    <span className="font-bold">
                      {" "}
                      {(
                        (paymentReceived /
                          (paymentReceived +
                            totalPrincipleDue +
                            totalInterestDue +
                            ioiDue +
                            penalDue)) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-6 mt-6">
                {recoveryDetails?.length > 0
                  ? recoveryDetails?.map(
                      ({
                        categoryname,
                        rp_amount,
                        principle_due,
                        int_due_amount,
                        ioi_due,
                        penal_due,
                        recovery_percentage,
                      }) => (
                        <>
                          {categoryname ? (
                            <>
                              <RecoveryCard
                                categoryname={categoryname}
                                rp_amount={rp_amount}
                                principle_due={principle_due}
                                int_due_amount={int_due_amount}
                                ioi_due={ioi_due}
                                penal_due={penal_due}
                                recovery_percentage={recovery_percentage}
                              />
                            </>
                          ) : null}
                        </>
                      )
                    )
                  : null}
              </div>
            </section>

            {showPrint ? (
              <div className="grid grid-cols-1 mt-6">
                {recoveryDetails?.length > 0 && selectedEndYear?.value ? (
                  <PrintReport reportTitle={"Yearly Wise Report"}>
                    <RecoveryReport_Print
                      start_year={new Date(
                        selectedStartYear?.value
                      ).getFullYear()}
                      end_year={new Date(selectedEndYear?.value).getFullYear()}
                      paymentReceived={paymentReceived}
                      recoveryDetails={recoveryDetails}
                      totalInterestDue={totalInterestDue}
                      totalPrincipleDue={totalPrincipleDue}
                      ioiDue={ioiDue}
                      penalDue={penalDue}
                    />
                  </PrintReport>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </Dashboard>
    </>
  );
};

export default RecoveryConfig;
