/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { REPAYMENT_CONFIG_URL } from "../../../../../api/api_routing_urls";
import useAuth from "../../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../../reusable-components/inputs/Dropdowns/Dropdown";
import TextArea from "../../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../../theme/lightTheme";
import { rePaymentFormModeDD } from "../../../../../utilities/constants/constants";
import { getFormattedDateInputDate } from "../../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../../reusable-components/modals/GenericModal";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";
import Receipt_In_Add_Repayment from "../../../ReportsList/Ledger/Receipt_In_Add_Repayment";
import Receipt_for_cheque_generation from "../../../ReportsList/Ledger/Receipt_for_cheque_generation";

const GenerateChequeForm = ({
  addRepaymentDetails,
  principleDueWithIntData,
  currentDateFromServer,
  setGenerateChequeReceipt,
  getInitialLoadCalculation,
  setRefreshPage,
  totalDueAmount,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  // console.log({ totalDueAmount });

  const [specficChequeDate, setSpecficChequeDate] = useState();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: principleDueWithIntData,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const handleFormSubmit = (data) => {
    console.log({ data });
    if (+data.cheque_amount > +0) {
      setFormData(data); // Store form data
      setShowModal(true); // Open modal
    } else {
      showToast(
        "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
        "error"
      );
    }
  };

  const onSubmit = async () => {
    try {
      let response = "";
      response = await axiosPrivate.post(
        `${REPAYMENT_CONFIG_URL}/saveChequeReceiptDetails`,
        {
          cheque_number: formData?.cheque_number,
          cheque_date: formData?.cheque_date || specficChequeDate,
          cheque_amount: formData?.cheque_amount,
          bank_name: formData?.bank_name,
          account_holder_name: formData?.account_holder_name,
          createdby: auth?.userid,
          loan_number: addRepaymentDetails?.loan_number,
          particulars: formData?.particulars,
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Cheque detail has been added successfully", "success");

        getInitialLoadCalculation();

        setRefreshPage((prev) => !prev);
      } else {
        showToast(
          "Whoops!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        return;
      }
      reset();
      setGenerateChequeReceipt(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setShowModal(false); // Close modal after submission
    }
  };

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="grid grid-cols-3 gap-x-3 ">
            <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2">
              <legend className="px-2 ml-3 bg-slate-700 rounded-sm text-white text-sm p-1">
                Generate Cheque Receipt
              </legend>

              <Input
                defaultName="cheque_number"
                register={register}
                name="Cheque No"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque no"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="numeric"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.cheque_number}
                setValue={setValue}
              />

              <Input
                defaultName="cheque_date"
                register={register}
                name="Cheque Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={(e) => {
                  setSpecficChequeDate(e);
                }}
                defaultValue={currentDateFromServer}
                setValue={setValue}
              />

              <Input
                defaultName="cheque_amount"
                register={register}
                name="Cheque Amount"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque amount"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                onChangeInput={(e) => {
                  // for form validation
                  console.table(e);
                }}
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                defaultValue={null}
                setValue={setValue}
                min={0}
              />
            </fieldset>
            <fieldset className="col-span-3 px-2 grid grid-cols-1 md:grid-cols-3 gap-x-3 text-sm my-3 ">
              <legend className="text-slate-700  text-sm  underline underline-offset-2">
                Other details
              </legend>

              <Input
                defaultName="bank_name"
                register={register}
                name="Bank Name issuing the cheque"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter bank name issuing the cheque"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.transectionid}
                setValue={setValue}
              />
              <Input
                defaultName="account_holder_name"
                register={register}
                name="Name of the account holder issuing the cheque"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter name of the account holder"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.transectionid}
                setValue={setValue}
              />

              <TextArea
                defaultName="particulars"
                register={register}
                name="Particulars"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter particulars"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.particulars}
                setValue={setValue}
              />
            </fieldset>
          </div>
          <div className="flex justify-between mt-6">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={"Submit"}
              className={button}
            />
            <Button
              onClick={() => {
                setGenerateChequeReceipt(false);
              }}
              aria-label="Cancel District Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>

      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        title={"Cheque Receipt"}
        isLarge={true}
        isAdd={false}
      >
        <Receipt_for_cheque_generation
          addRepaymentDetails={addRepaymentDetails}
          formData={formData}
        />

        <div className="flex justify-end mt-6 gap-x-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Confirm"}
            className={button}
            onClick={onSubmit}
          />
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            aria-label="Cancel Modal Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </GenericModal>
    </>
  );
};
export default GenerateChequeForm;
