import React, { useEffect } from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { CiCalendar } from "react-icons/ci";
import RecoveryCard from "./RecoveryCard";

const RecoveryReport_Print = ({
  start_year,
  end_year,
  paymentReceived,
  recoveryDetails,
  totalInterestDue,
  totalPrincipleDue,
  ioiDue,
  penalDue,
}) => {
  return (
    <>
      <section className="mt-3 text-[12px]">
        <div className="flex items-start justify-center gap-1 my-1">
          <CiCalendar size={18} />
          <div className="font-semibold text-[14px]">
            <span>{`${start_year} - ${end_year}`}</span>
          </div>
        </div>

        <section className="mt-3 !text-[11px]">
          <div className="text-yellow-700 p-1">
            * The recovery report specifically focuses on borrowers who received
            loans between selected fiscal year..
          </div>
          <div className="border border-slate-300 p-6">
            <div
              className=" text-primary font-semibold border bg-slate-100 p-1 
                grid grid-cols-2"
            >
              <div className="pl-3">All Category:</div>
              <div>
                {" "}
                {(
                  (paymentReceived /
                    (paymentReceived +
                      totalPrincipleDue +
                      totalInterestDue +
                      ioiDue +
                      penalDue)) *
                  100
                ).toFixed(2)}
                %
              </div>
            </div>
            <div className=" bg-slate-50  grid grid-cols-2 gap-x-3 md:gap-x-6 pt-6">
              <div className="grid grid-cols-2 items-center">
                Payment Received:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(paymentReceived)}
                </span>
              </div>

              <div className="grid grid-cols-2 items-center">
                Principle Due:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalPrincipleDue)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Interest Due Amount:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalInterestDue)}
                </span>
              </div>
              {ioiDue ? (
                <div className="grid grid-cols-2 items-center">
                  Interest on Interest Due :
                  <span className="font-bold">
                    {" "}
                    {formatINRCurrency(ioiDue)}
                  </span>
                </div>
              ) : null}
              {penalDue ? (
                <div className="grid grid-cols-2 items-center">
                  Penal Interest Due :
                  <span className="font-bold">
                    {" "}
                    {formatINRCurrency(penalDue)}
                  </span>
                </div>
              ) : null}
              <div className="grid grid-cols-2 items-center">
                Principle Due:
                <span className="font-bold">
                  {" "}
                  {(
                    (paymentReceived /
                      (paymentReceived +
                        totalPrincipleDue +
                        totalInterestDue +
                        ioiDue +
                        penalDue)) *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-3 gap-3 ">
            {recoveryDetails?.length > 0
              ? recoveryDetails?.map(
                  ({
                    categoryname,
                    rp_amount,
                    principle_due,
                    int_due_amount,
                    ioi_due,
                    penal_due,
                    recovery_percentage,
                  }) => (
                    <>
                      {categoryname ? (
                        <>
                          <RecoveryCard
                            categoryname={categoryname}
                            rp_amount={rp_amount}
                            principle_due={principle_due}
                            int_due_amount={int_due_amount}
                            ioi_due={ioi_due}
                            penal_due={penal_due}
                            recovery_percentage={recovery_percentage}
                            to_print={true}
                          />
                        </>
                      ) : null}
                    </>
                  )
                )
              : null}
          </div>
        </section>
      </section>
    </>
  );
};

export default RecoveryReport_Print;
