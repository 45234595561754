import React, { useEffect, useRef, Fragment, useState } from "react";
import {
  MdAssignmentTurnedIn,
  MdOutlineFilterAlt,
  MdOutlineSearch,
  MdPrint,
} from "react-icons/md";
import { CiCalculator2, CiCalendar, CiMoneyCheck1 } from "react-icons/ci";
import { AiOutlineBranches } from "react-icons/ai";
import { IoCashOutline } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
  REPORTS_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import FilterInput from "../../../../reusable-components/search/FilterInput";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
  getFormattedDateMMMYYYY,
} from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";
import MonthlyReportList from "./MonthlyReportList";
import ReactToPrint from "react-to-print";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import ReportCard from "../ReportCard";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import { PrintReport } from "../PrintReport/PrintReport";
import MonthlyReportListPrint from "./MonthlyReportListToPrint";
import useGetAxios from "../../../../hook/useGetAxios";
import { BsDot } from "react-icons/bs";
import { genderDD } from "../../../../utilities/constants/constants";
import { CSVLink } from "react-csv";

const MonthlyReportConfig = () => {
  const { auth } = useAuth();
  const [monthlyReportList, setMonthlyReportList] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(false);

  const [specificDate, setSpecificDate] = useState();

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const [selectedApplicantGender, setSelectedApplicantGender] = useState();

  const controller = new AbortController();

  const getCurrentDateUrl = `${REPAYMENT_CONFIG_URL}/getCurrentDateFromServer`;
  const getCategoryUrl = `${CATEGORY_CONFIG_URL}`;
  const getConstituencyUrl = `${CONSTITUENCY_CONFIG_URL}`;
  const getDistrictUrl = `${DISTRICT_CONFIG_URL}`;

  const { data: currentDateData } = useGetAxios(getCurrentDateUrl);
  const { data: categoryData } = useGetAxios(getCategoryUrl);
  const { data: constituencyData } = useGetAxios(getConstituencyUrl);
  const { data: districtListData } = useGetAxios(getDistrictUrl);

  const getCurrentDateFromServer = () => {
    if (currentDateData) {
      setSpecificDate(
        getFormattedDateInputDate(currentDateData?.currentDateFromServer)
      );
      getMonthlyReportDetail(
        getFormattedDateInputDate(currentDateData?.currentDateFromServer)
      );
    }
  };
  // get category list
  const getCategoryList = async () => {
    if (categoryData) {
      if (categoryData?.categoryList?.length > 0) {
        let categoryDD = [];

        categoryData?.categoryList?.map((categoryOBJ) => {
          let categoryObj = {
            value: categoryOBJ?.categoryid,
            label: categoryOBJ?.categoryname,
          };

          categoryDD.push(categoryObj);
        });
        setCategoryList(categoryDD);
      } else {
        setCategoryList([]);
      }
    }
  };
  // get District List
  const getDistrictList = async () => {
    if (districtListData) {
      if (districtListData?.districtList?.length > 0) {
        let districtDD = [];
        districtListData?.districtList?.map((districtObj) => {
          let districtObjDD = {
            value: districtObj.districtid,
            label: districtObj.districtname,
          };
          districtDD.push(districtObjDD);
        });
        setDistrictList(districtDD);
      } else {
        setDistrictList([]);
      }
    }
  };
  // get constituency list
  const getConstituencyList = async () => {
    if (constituencyData) {
      if (constituencyData?.constituencyList?.length > 0) {
        let constituencyDD = [];
        constituencyData?.constituencyList?.map((constituencyObj) => {
          let constituencyObjDD = {
            value: constituencyObj.constituencyid,
            label: constituencyObj.constituencyname,
          };
          constituencyDD.push(constituencyObjDD);
        });
        setConstituencyList(constituencyDD);
      } else {
        setConstituencyList([]);
      }
    }
  };

  // get searched repayment list
  const getMonthlyReportDetail = async () => {
    try {
      setIsLoading(true);

      if (specificDate) {
        const response = await axiosPrivate.get(
          `${REPORTS_CONFIG_URL}/getMonthlyReportConfig`,
          {
            params: {
              specficdate: getFormattedDateInputDate(new Date(specificDate)),
              categoryname: selectedCategory?.label || "",
              districtname: selectedDistrictName?.label || "",
              constituencyname: selectedConstituencyName?.label || "",
              applicantgender: selectedApplicantGender?.label || "",
            },
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status === 200) {
          setMonthlyReportList(response?.data?.monthlyReport);
        }

        response.status === 202 &&
          showToast("No state list found in the system", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && specificDate) {
      setTimeout(() => {
        getMonthlyReportDetail(specificDate);
      }, 1000);
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    specificDate,
    selectedCategory?.value,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCurrentDateFromServer();
      getCategoryList();
      getConstituencyList();
      getDistrictList();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [currentDateData, categoryData, constituencyData, districtListData]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <>
          <LoadingModal message={"Loading"} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdAssignmentTurnedIn />{" "}
              <span className="pl-1.5">Monthly Progress Report </span>
            </H2withIcon>
          </div>
        </>
      )}
      <>
        <section className="flex flex-wrap gap-3 justify-between items-end mb-2">
          {/* <FilterInput
            name="Select Date"
            placeholder="Select Date"
            autoComplete="off"
            type="date"
            classes={`text-sm w-full rounded-sm h-8`}
            onChangeInput={setSpecificDate}
            defaultValue={specificDate}
          /> */}

          <FilterInput
            name="Select Month"
            placeholder="Select Month"
            autoComplete="off"
            type="month"
            classes={`text-sm w-full rounded h-8`}
            onChangeInput={setSpecificDate}
            // defaultValue={getFormattedDateInputDate(startDate)}
          />

          <div className=" flex gap-2">
            <Button
              className={
                "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Apply Filters
              <MdOutlineFilterAlt size={18} />
            </Button>

            <Button
              className={
                "bg-primary text-white text-sm px-6 md:px-3 py-2 md:min-w-36  flex items-center justify-center gap-1"
              }
              onClick={() => getMonthlyReportDetail(specificDate)}
            >
              Search
              <MdOutlineSearch size={18} />
            </Button>
          </div>
        </section>

        {showFilters && (
          <section className="grid grid-cols-1 md:grid-cols-4 gap-2 mb-2 bg-slate-100 border py-3 px-2 ">
            <FilterDropdown
              defaultName="filterCategory"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Category..."}
              data={categoryList}
              defaultValue={null}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterGender"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Gender..."}
              data={genderDD}
              defaultValue={null}
              setSelected={setSelectedApplicantGender}
              selected={selectedApplicantGender}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterDistrict"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select District..."}
              data={districtList}
              defaultValue={null}
              setSelected={setSelectedDistrictName}
              selected={selectedDistrictName}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterConstituency"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Constituency..."}
              data={constituencyList}
              defaultValue={null}
              setSelected={setSelectedConstituencyName}
              selected={selectedConstituencyName}
              maxMenuHeight={256}
            />
          </section>
        )}
      </>

      <>
        <section
          className="mt-4 text-sm bg-slate-50
          grid grid-cols-1 md:grid-cols-4 gap-3 border p-3"
        >
          <div className=" md:col-span-4 text-slate-800 flex justify-between items-center">
            <div className="flex flex-wrap items-center gap-3 ">
              <div className="flex items-center gap-1">
                <CiCalendar size={19} />
                <span className="font-semibold">
                  {getFormattedDateMMMYYYY(specificDate)
                    ? getFormattedDateMMMYYYY(specificDate)
                    : "-- , --"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                Category:{" "}
                <span className="font-semibold ml-1">
                  {selectedCategory ? selectedCategory?.label : "N/A"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                District:{" "}
                <span className="font-semibold ml-1">
                  {selectedDistrictName ? selectedDistrictName?.label : "N/A"}
                </span>
              </div>
              <BsDot size={20} />
              <div>
                Constituency:{" "}
                <span className="font-semibold ml-1">
                  {selectedConstituencyName
                    ? selectedConstituencyName?.label
                    : "N/A"}
                </span>
              </div>
              <BsDot size={20} />
              <div>
                Gender:{" "}
                <span className="font-semibold ml-1">
                  {selectedApplicantGender
                    ? selectedApplicantGender?.label
                    : "N/A"}
                </span>
              </div>
            </div>

            <div className="md:justify end">
              No. of Repayments:{" "}
              <span className="font-semibold ml-1">
                {monthlyReportList?.monthly_report?.length || 0}
              </span>
            </div>
          </div>

          <ReportCard
            title={"Cash"}
            principle={monthlyReportList?.repay_bal_cash_amt}
            interest={monthlyReportList?.repay_int_cash_amt}
            penal_Interest={monthlyReportList?.repay_pi_cash_amt}
            interest_on_interest={monthlyReportList?.repay_ioi_cash_amt}
            ots_repayment={monthlyReportList?.repay_ots_cash_amt}
          />
          <ReportCard
            title={"Cheque"}
            principle={monthlyReportList?.repay_bal_cheque_amt}
            interest={monthlyReportList?.repay_int_cheque_amt}
            penal_Interest={monthlyReportList?.repay_pi_cheque_amt}
            interest_on_interest={monthlyReportList?.repay_ioi_cheque_amt}
            ots_repayment={monthlyReportList?.repay_ots_cheque_amt}
          />
          <ReportCard
            title={"Branch"}
            principle={monthlyReportList?.repay_bal_branch_amt}
            interest={monthlyReportList?.repay_int_branch_amt}
            penal_Interest={monthlyReportList?.repay_pi_branch_amt}
            interest_on_interest={monthlyReportList?.repay_ioi_branch_amt}
            ots_repayment={monthlyReportList?.repay_ots_branch_amt}
          />
          <ReportCard
            title={"Total"}
            principle={
              +monthlyReportList?.repay_bal_cash_amt +
              +monthlyReportList?.repay_bal_cheque_amt +
              +monthlyReportList?.repay_bal_branch_amt
            }
            interest={
              +monthlyReportList?.repay_int_cash_amt +
              +monthlyReportList?.repay_int_cheque_amt +
              +monthlyReportList?.repay_int_branch_amt
            }
            penal_Interest={
              +monthlyReportList?.repay_pi_cash_amt +
              +monthlyReportList?.repay_pi_cheque_amt +
              +monthlyReportList?.repay_pi_branch_amt
            }
            interest_on_interest={
              +monthlyReportList?.repay_ioi_cash_amt +
              +monthlyReportList?.repay_ioi_cheque_amt +
              +monthlyReportList?.repay_ioi_branch_amt
            }
            ots_repayment={
              +monthlyReportList?.repay_ots_cash_amt +
              +monthlyReportList?.repay_ots_cheque_amt +
              +monthlyReportList?.repay_ots_branch_amt
            }
          />
        </section>
      </>
      {/* 
      <MonthlyReportList monthlyReportList={monthlyReportList} /> */}

      {!isLoading ? (
        <>
          <div className="flex justify-between items-center">
            <div>
              {monthlyReportList?.monthly_report?.length > 0 ? (
                <PrintReport reportTitle={"Monthly Report"}>
                  <MonthlyReportListPrint
                    monthlyReportList={monthlyReportList}
                    specificDate={specificDate}
                    selectedCategory={selectedCategory}
                    selectedDistrictName={selectedDistrictName}
                    selectedApplicantGender={selectedApplicantGender}
                    selectedConstituencyName={selectedConstituencyName}
                  />
                </PrintReport>
              ) : null}
            </div>
            <div>
              {monthlyReportList?.monthly_report?.length > 0 ? (
                <>
                  <div>
                    <CSVLink
                      className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-3"
                      data={monthlyReportList?.monthly_report}
                      target="_blank"
                      filename={`SABCCO-MonthlyReportList-Reports/${currentDate}.csv`}
                    >
                      Export CSV
                    </CSVLink>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div>
            <MonthlyReportList monthlyReportList={monthlyReportList} />
          </div>
        </>
      ) : null}
    </Dashboard>
  );
};

export default MonthlyReportConfig;
