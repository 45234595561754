import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

export default function PrivacyPolicy() {
  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Privacy Policy</H1>
          </div>
        </div>
      </HeroBanner>
      <section className="bg-white pb-12">
        <div className="container mx-auto py-9 px-9">
          <div className="font-bold text-2xl lg:text-3xl flex justify-center text-[#001D31] pt-9">
            Eligibility Criteria
          </div>
          <div className="mt-9 text-slate-800 text-sm leading-7">
            <h3 className="font-semibold text-xl mb-4">For SCs, STs & OBCs</h3>
            <ul className="list-disc list-inside mb-6">
              <li>
                Beneficiary should be SC, ST, OBC, or Minorities domiciled in
                Sikkim, or a Cooperative Society, or a legally constituted
                Association/Firms promoted by members of SC, ST, OBC, and
                Minorities.
              </li>
              <li>
                Annual income of the beneficiary or members of a Cooperative
                Society or legally constituted association/firms should not
                exceed Rs. 3,00,000/- per annum.
              </li>
            </ul>
            <h3 className="font-semibold text-xl mb-4">
              For Handicapped/Disabled
            </h3>
            <ul className="list-disc list-inside">
              <li>Any person with 40% or more disability.</li>
              <li>Age between 18 to 55 years.</li>
              <li>A cooperative society of disabled persons.</li>
              <li>A legally constituted association of disabled persons.</li>
              <li>A firm promoted by disabled persons.</li>
              <li>
                Each member of society/association/firm applying for a loan
                should fulfill the disability, age, and income criteria.
              </li>
              <li>
                Relevant educational/technical/vocational qualification,
                experience, and background.
              </li>
            </ul>
          </div>
        </div>

        <div className="container mx-auto py-9 px-9">
          <div className="font-bold text-2xl lg:text-3xl flex justify-center text-[#001D31] ">
            Finance Programme
          </div>
          <div className="mt-9 text-slate-800 text-sm leading-7">
            <h3 className="font-semibold text-xl mb-4">Term Loan</h3>
            <p>
              The Corporation extends finance by way of term loan for a wide
              range of income generating activities, but does not finance any
              infrastructure development schemes unless such schemes lead
              directly to income generation.
            </p>

            <h3 className="font-semibold text-xl mb-4 mt-6">Micro-Credit</h3>
            <p>
              The Corporation also extends assistance to promote micro-credit
              scheme at the grass root level to the low-income groups in the
              rural, semi-urban and urban areas to enable them to raise their
              income level and be self-reliant. Under this scheme, loans will be
              provided either directly or through NGOs and SHGs.
            </p>
            <ul className="list-disc list-inside mb-6">
              <li>
                The NGOs will organize SHGs of micro-entrepreneurs, arrange
                skill development training programmes and extend financial
                assistance to these groups.
              </li>
              <li>
                The Corporation will extend loans to the NGOs for undertaking
                such activities.
              </li>
            </ul>

            <h3 className="font-semibold text-xl mb-4 mt-6">
              Schemes for Assistance
            </h3>
            <ul className="list-disc list-inside mb-6">
              <li>
                Agriculture and Allied: Agriculture production and related
                fields such as purchase of agriculture machinery and implements,
                animal breeding, poultry, dairy, piggery, and bee-keeping.
                Horticulture, floriculture, mushroom cultivation, cash crops
                like cardamom, orange, apple, potato, ginger, etc.
              </li>
              <li>
                Artisan and Traditional Occupation: These include handicrafts,
                tailoring, weaving, knitting, carpet weaving, jewellery,
                carpentry, blacksmith, bakery, beauty parlour, etc.
              </li>
              <li>
                Technical Trades: All self-employment schemes such as
                electrician, plumber, steel fabrication works, TV/Radio repair,
                motor mechanic, book binding, clinical labs, Xerox/Fax/Typing,
                Lamination Centres, STD/PCO booths, etc.
              </li>
              <li>
                Hotel/Guest House/Lodging/Restaurant: The loan facility is
                available to SC/ST/OBC/Persons with Disabilities/Minorities
                beneficiaries only for conversion of, renovation of rooms,
                kitchen, etc. of existing building to guest houses/lodging in
                areas having tourism potential.
              </li>
              <li>
                Small Business: Loans for setting up small business ventures
                such as tea shops, general provision stores, grocery/manihari
                shops, readymade garments shop, newspaper/magazine shop, etc.
              </li>
              <li>
                Transport Services: Purchase of commercial vehicles for
                self-employment.
              </li>
            </ul>

            <h3 className="font-semibold text-xl mb-4 mt-6">
              Procedure for Availing Loans
            </h3>
            <ul className="list-disc list-inside mb-6">
              <li>
                Submission of application form in the prescribed format
                (available in the Corporation’s Office) duly filled in along
                with a passport size photograph duly attested by a Gazetted
                Officer.
              </li>
              <li>
                Certificate of Identification issued by District Collector/Addl.
                District Collector/Sub-Div. Magistrate.
              </li>
              <li>
                SC/ST/OBC/MBC Certificate issued by District
                Collector/Additional District Collector/Sub-Div. Magistrate.
              </li>
              <li>
                Annual family Income Certificate issued by District
                Collector/Additional District Collector/Sub-Div. Magistrate.
              </li>
              <li>Land title/Parcha.</li>
              <li>Salary Certificate of Guarantor.</li>
              <li>Project Report/Profile.</li>
              <li>
                For handicapped/disabled persons, certificate from Medical Board
                certifying disability.
              </li>
            </ul>

            <h3 className="font-semibold text-xl mb-4 mt-6">
              Policy for Payment Gateway Implementation
            </h3>
            <h4 className="font-semibold text-lg mb-3">Scope and Purpose</h4>
            <ul className="list-disc list-inside mb-6">
              <li>
                The payment gateway aims to facilitate smooth transactions for
                loan disbursements and repayments for the beneficiaries of
                SABCCO's financial assistance programs.
              </li>
              <li>
                It ensures transparency, security, and efficiency in financial
                transactions between SABCCO and its beneficiaries.
              </li>
            </ul>

            <h4 className="font-semibold text-lg mb-3">User Eligibility</h4>
            <p>
              All beneficiaries of SABCCO belonging to Scheduled Castes,
              Scheduled Tribes, Other Backward Classes, Persons with
              Disabilities, and Minorities living Below Double the Poverty Line
              who have taken loan from SABCCO are eligible to utilize the
              payment gateway.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Transaction Security
            </h4>
            <ul className="list-disc list-inside mb-6">
              <li>
                The payment gateway will implement industry-standard encryption
                protocols to safeguard the confidentiality and integrity of
                financial transactions.
              </li>
              <li>
                Multi-factor authentication will be enforced to prevent
                unauthorized access to beneficiary accounts.
              </li>
            </ul>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Transaction Limits
            </h4>
            <p>
              Clear transaction limits will be set for both loan disbursements
              and repayments, ensuring compliance with regulatory requirements
              and mitigating financial risks.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">Fee Structure</h4>
            <p>
              Transparent fee structures will be established, clearly outlining
              any processing fees or service charges associated with
              transactions. These fees should be minimal to ensure affordability
              for beneficiaries.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Refund/Cancellation
            </h4>
            <p>
              If a loanee overpays an installment, the extra amount will be
              applied to the principle amount. At the end of the loan term, any
              remaining overpayment can be refunded by presenting proof of
              payment. SABCCO does not offer loan installment cancellations.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">Contact Us</h4>
            <ul className="list-none mb-6">
              <li>Name: Arun Gurung</li>
              <li>E-mail: arungurung1975@gmail.com</li>
              <li>Phone No: 8372038920</li>
            </ul>

            <p className="mb-6">
              Your Message: Our new payment gateway is live, making transactions
              with SABCCO easier and safer. Need help? Our support team is here
              for you. Let's empower together!
            </p>

            <p className="mb-6">Registration No.: Item No.537 Volume No. I</p>

            <h4 className="font-semibold text-lg mb-3 mt-6">Address</h4>
            <ul className="list-none mb-6">
              <li>Sonam Tshering Marg, Gangtok East Sikkim -737101</li>
              <li>Call Us</li>
              <li>Phone: 8372038920</li>
              <li>E-mail: arungurung1975@gmail.com</li>
            </ul>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Customer Support
            </h4>
            <ul className="list-disc list-inside mb-6">
              <li>
                Dedicated customer support channels will be provided to assist
                beneficiaries in case of any issues or queries related to
                transactions conducted through the payment gateway.
              </li>
              <li>
                Support services will be accessible through multiple channels
                including phone, email, and online chat.
              </li>
            </ul>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Data Privacy and Compliance
            </h4>
            <ul className="list-disc list-inside mb-6">
              <li>
                The payment gateway will adhere to all relevant data protection
                regulations to safeguard the privacy and confidentiality of
                beneficiary information.
              </li>
              <li>
                Regular audits and assessments will be conducted to ensure
                compliance with applicable regulatory frameworks.
              </li>
            </ul>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Integration with Existing Systems
            </h4>
            <ul className="list-disc list-inside mb-6">
              <li>
                Seamless integration with SABCCO's existing software
                infrastructure will be ensured to facilitate real-time updates
                and reconciliation of transaction data.
              </li>
              <li>
                Compatibility with various devices and operating systems will be
                considered to accommodate diverse user preferences.
              </li>
            </ul>

            <h3 className="font-semibold text-xl mb-4 mt-6">
              Terms and Conditions
            </h3>

            <h4 className="font-semibold text-lg mb-3">Account Registration</h4>
            <p>
              Beneficiaries must register an account with SABCCO's payment
              gateway platform using valid identification documents and contact
              information.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Transaction Authorization
            </h4>
            <p>
              Beneficiaries are responsible for ensuring the accuracy and
              completeness of transaction details before authorizing any
              payments or loan disbursements through the payment gateway.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Payment Settlement
            </h4>
            <p>
              Loan repayments made through the payment gateway will be credited
              to the beneficiary's account within a specified timeframe, subject
              to processing and clearance by financial institutions.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Transaction Disputes
            </h4>
            <p>
              Beneficiaries can raise disputes regarding unauthorized
              transactions or discrepancies in transaction records through the
              designated customer support channels within a reasonable
              timeframe.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Termination of Services
            </h4>
            <p>
              SABCCO reserves the right to suspend or terminate access to the
              payment gateway in cases of fraudulent activities, violation of
              terms and conditions, or non-compliance with regulatory
              requirements.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Modification of Terms
            </h4>
            <p>
              SABCCO retains the authority to modify or update the terms and
              conditions governing the use of the payment gateway platform, with
              prior notice to beneficiaries.
            </p>

            <h4 className="font-semibold text-lg mb-3 mt-6">
              Legal Jurisdiction
            </h4>
            <p>
              All legal matters, disputes, or proceedings arising from the use
              of the payment gateway and related services provided by SABCCO
              shall be governed by the laws of Gangtok, Sikkim. Any legal
              proceedings or disputes shall fall under the exclusive
              jurisdiction of the courts in Gangtok, Sikkim, India.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
