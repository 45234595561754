import React from "react";
import { NavLink } from "react-router-dom";
import SidebarLinkGroup from "../../common/SidebarLinkGroup";
import SidebarIcons from "../../common/SidebarIcons";
import { AiOutlineLineChart } from "react-icons/ai";

const ReportSidebar = ({
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
  accessRole,
}) => {
  return (
    <>
      {/* ReportList */}
      <SidebarLinkGroup activecondition={pathname.includes("reports-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("reports-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("reports-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <AiOutlineLineChart size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Reports
                    </span>
                  </div>
                  {/* Icon */}
                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-dailyReport`}
                      className={`block 
                       ${
                         pathname.includes("dailyReport")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Daily Report
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-monthlyReport`}
                      className={`block 
                       ${
                         pathname.includes("monthlyReport")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Monthly Report
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-year-wise`}
                      className={`block 
                       ${
                         pathname.includes("yearly-wise")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Year Wise Report
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-repaymentLedger`}
                      className={`block 
                       ${
                         pathname.includes("repaymentLedger")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Ledger Report
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-loan-outstanding-ledger`}
                      className={`block 
                       ${
                         pathname.includes("loan-outstanding-ledger")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Loan Outstanding Ledger
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-loan-ots-outstanding-ledger`}
                      className={`block 
                       ${
                         pathname.includes("loan-ots-outstanding-ledger")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        OTS Outstanding Ledger
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-sanctionDetails`}
                      className={`block 
                       ${
                         pathname.includes("sanctionDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Sanction Detail
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-disbursmentDetails`}
                      className={`block 
                       ${
                         pathname.includes("disbursmentDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Disbursment Detail
                      </span>
                    </NavLink>
                  </li>
                  {/* 
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/reports-repaymentLedger"
                      className={`block 
                       ${
                         pathname.includes("repaymentLedger")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Repayment Report
                      </span>
                    </NavLink>
                  </li> */}

                  {/* <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/reports-penal"
                      className={`block 
                       ${
                         pathname.includes("penal")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Penal Interest
                      </span>
                    </NavLink>
                  </li> */}
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-nillRepaymentList`}
                      className={`block 
                       ${
                         pathname.includes("nillRepaymentList")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Nill Repayment Detail
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/reports-recoveryPercentage"
                      className={`block 
                       ${
                         pathname.includes("recoveryPercentage")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Recovery Percentage
                      </span>
                    </NavLink>
                  </li> */}

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-fiscalYear-recoveryPercentage`}
                      className={`block 
                       ${
                         pathname.includes("recoveryPercentage")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Recovery Percentage
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to={`/${accessRole}/reports-yearly-compiled`}
                      className={`block 
                       ${
                         pathname.includes("yearly-compiled")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Compiled Report
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>
    </>
  );
};

export default ReportSidebar;
