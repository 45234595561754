import React, { useEffect, useRef, Fragment, useState } from "react";
import {
  MdAssignmentTurnedIn,
  MdFilterListAlt,
  MdOutlineFilterAlt,
  MdOutlineSearch,
  MdPrint,
} from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  AREA_CONFIG_URL,
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  GRAMPANCHAYAT_CONFIG_URL,
  MUNICIPAL_CORPORATION_CONFIG_URL,
  NAGAR_PANCHAYAT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
  REPORTS_CONFIG_URL,
  SCHEME_CONFIG_URL,
  SUBDIVISION_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import FilterInput from "../../../../reusable-components/search/FilterInput";
import SearchField from "../../../../reusable-components/search/SearchField";
import {
  genderDD,
  loanStatusDD,
} from "../../../../utilities/constants/constants";
import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";
import LoanOTSOutstandingLedgerList from "./LoanOTSOutstandingLedgerList";
import ReactToPrint from "react-to-print";
import LoanOTSOutstandingLedgerListPrint from "./LoanOTSOutstandingLedgerListPrint";
import { PrintReport } from "../PrintReport/PrintReport";
import H5 from "../../../../reusable-components/headings/H5";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

const LoanOTSOutstandingLedgerConfig = () => {
  const { auth } = useAuth();
  const [loanOTSOutstandingLedgerList, setLoanOTSOutstandingLedgerList] =
    useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editRepaymentDetails, setEditRepaymentDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [numberOfLoanee, setNumberOfLoanee] = useState(0);
  const [recoveryPercentage, setRecoveryPercentage] = useState(0);
  const [totalValues, setTotalValues] = useState({});

  const [categoryList, setCategoryList] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [gramPanchayatList, setGramPanchayatList] = useState([]);
  const [nagarPanchayatList, setNagarPanchayatList] = useState([]);
  const [municipalCorporationList, setMunicipalCorporationList] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedLoanStatus, setSelectedLoanStatus] = useState();
  const [selectedSchemeName, setSelectedSchemeName] = useState();
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [selectedSubDivisionName, setSelectedSubDivisionName] = useState();
  const [selectedGramPanchayatName, setSelectedGramPanchayatName] = useState();
  const [
    selectedMumicipalCorporationName,
    setSelectedMumicipalCorporationName,
  ] = useState();
  const [selectedNagarPanchayatName, setSelectedNagarPanchayatName] =
    useState();
  const [selectedArea, setSelectedArea] = useState();

  const [showFilters, setShowFilters] = useState(false);

  const controller = new AbortController();

  const componentRefToPrint = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRefToPrint.current,
  // });

  // get searched repayment list
  const getLoanOTSOutstandingLedgerList = async () => {
    try {
      setIsLoading(true);

      const getLedgerReport = {
        till_date: getFormattedDateInputDate(startDate),
        district_id: selectedDistrictName?.value || null,
        category_id: selectedCategory?.value || null,
        scheme_id: selectedSchemeName?.value || null,
        loan_status: selectedLoanStatus?.value || null,
        area_id: selectedArea?.value || null,
        constituency_id: selectedConstituencyName?.value || null,
      };

      const response = await axiosPrivate.post(
        `${REPORTS_CONFIG_URL}/getOTSLoanOutstandingLedger`,
        getLedgerReport,
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setLoanOTSOutstandingLedgerList(
          response?.data?.report?.loan_outstanding_ledger
        );
        setNumberOfLoanee(response?.data?.report?.no_of_loanee);
        setRecoveryPercentage(response?.data?.report?.recovery_percentage);
        setTotalValues(response?.data?.report?.total_values);
        console.log("response?.data?.report", response?.data?.report);
      }
      response.status === 202 &&
        showToast("No Record found in the system", "warning");

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get category list
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  // get scheme list
  const getSchemeList = async () => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.schemeList?.length > 0) {
          let SchemeDD = [];
          response?.data?.schemeList?.map((schemeObj) => {
            let SchemeObj = {
              value: schemeObj.schemeid,
              label: `${schemeObj.schemename}
              ${" "}(${schemeObj.schemeshortname})`,
            };
            SchemeDD.push(SchemeObj);
          });
          setSchemeList(SchemeDD);
        } else {
          setSchemeList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  // get scheme list
  const getAreaList = async () => {
    try {
      const response = await axiosPrivate.get(AREA_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.areaList?.length > 0) {
          let SchemeDD = [];
          response?.data?.areaList?.map((schemeObj) => {
            let SchemeObj = {
              value: schemeObj.area_id,
              label: `${schemeObj.area_name}`,
            };
            SchemeDD.push(SchemeObj);
          });
          setAreaList(SchemeDD);
        } else {
          setAreaList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get District List
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get constituency list
  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get sub division
  const getSubDivisionList = async () => {
    try {
      const response = await axiosPrivate.get(SUBDIVISION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.subDivisionList?.length > 0) {
          let subDivisionDD = [];
          response?.data?.subDivisionList?.map((subDivisionObj) => {
            let subDivisionObjDD = {
              value: subDivisionObj?.subdivisionid,
              label: subDivisionObj?.subdivisionname,
            };
            subDivisionDD.push(subDivisionObjDD);
          });
          setSubDivisionList(subDivisionDD);
        } else {
          setSubDivisionList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get gram panchayat list
  const getGramPanchayatList = async () => {
    try {
      const response = await axiosPrivate.get(GRAMPANCHAYAT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.grampanchayatList?.length > 0) {
          let gramPanchayatDD = [];
          response?.data?.grampanchayatList?.map((gramPanchayatObj) => {
            let gramPanchayatObjDD = {
              value: gramPanchayatObj.grampanchayatid,
              label: gramPanchayatObj.grampanchayatname,
            };
            gramPanchayatDD.push(gramPanchayatObjDD);
          });
          setGramPanchayatList(gramPanchayatDD);
        } else {
          setGramPanchayatList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get nagar panchayat
  const getNagarPanchayatList = async () => {
    try {
      const response = await axiosPrivate.get(NAGAR_PANCHAYAT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.nagarPanchayatList?.length > 0) {
          let nagarPanchayatDD = [];
          response?.data?.nagarPanchayatList?.map((nagarpanchayatObj) => {
            let nagarPanchayatObjDD = {
              value: nagarpanchayatObj.nagarpanchayatid,
              label: nagarpanchayatObj.nagarpanchayatname,
            };
            nagarPanchayatDD.push(nagarPanchayatObjDD);
          });
          setNagarPanchayatList(nagarPanchayatDD);
        } else {
          setNagarPanchayatList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get municipal corporation
  const getMunicipalCorporationList = async () => {
    try {
      const response = await axiosPrivate.get(
        MUNICIPAL_CORPORATION_CONFIG_URL,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.municipalCorporationList?.length > 0) {
          let municipalCorporationDD = [];
          response?.data?.municipalCorporationList?.map(
            (municipalCorporationObj) => {
              let municipalCorporationObjDD = {
                value: municipalCorporationObj?.municipalcorporationid,
                label: municipalCorporationObj?.municipalcorporationname,
              };
              municipalCorporationDD.push(municipalCorporationObjDD);
            }
          );

          setMunicipalCorporationList(municipalCorporationDD);
        } else {
          setMunicipalCorporationList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && startDate && endDate) {
      getLoanOTSOutstandingLedgerList();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    selectedCategory?.value,
    selectedLoanStatus?.value,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCategoryList();
      getSchemeList();
      getConstituencyList();
      getGramPanchayatList();
      getDistrictList();
      getMunicipalCorporationList();
      getSubDivisionList();
      getNagarPanchayatList();
      getAreaList();

      setEditRepaymentDetails({});
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <>
          <LoadingModal message={"Loading"} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdAssignmentTurnedIn />{" "}
              <span className="pl-1.5">OTS Outstanding Ledger</span>
            </H2withIcon>
          </div>
        </>
      )}

      <>
        <section className="flex flex-wrap gap-3 justify-between items-end mb-2">
          <div className="grid grid-cols-2 gap-2 ">
            <FilterInput
              name="Select a Date"
              placeholder="Select a Date"
              autoComplete="off"
              type="date"
              classes={`text-sm w-full rounded h-8`}
              onChangeInput={setStartDate}
              // defaultValue={getFormattedDateInputDate(startDate)}
            />
          </div>
          <div className="flex gap-2">
            <Button
              className={
                "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Apply Filters
              <MdOutlineFilterAlt size={18} />
            </Button>
            <Button
              className={
                "bg-primary text-white text-sm px-3 py-2 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => getLoanOTSOutstandingLedgerList()}
            >
              Search
              <MdOutlineSearch size={18} />
            </Button>
          </div>
        </section>
        {showFilters && (
          <section className="grid grid-cols-1 md:grid-cols-4 gap-y-3 gap-x-2 mb-2 bg-slate-50 border py-3 px-3 ">
            <FilterDropdown
              defaultName="filterCategory"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Category..."}
              data={categoryList}
              defaultValue={null}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterDistrict"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select District..."}
              data={districtList}
              defaultValue={null}
              setSelected={setSelectedDistrictName}
              selected={selectedDistrictName}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterConstituency"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Constituency..."}
              data={constituencyList}
              defaultValue={null}
              setSelected={setSelectedConstituencyName}
              selected={selectedConstituencyName}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterScheme"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Scheme..."}
              data={schemeList}
              defaultValue={null}
              setSelected={setSelectedSchemeName}
              selected={selectedSchemeName}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterArea"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Area..."}
              data={areaList}
              defaultValue={null}
              setSelected={setSelectedArea}
              selected={selectedArea}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="loan_status"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Loan Status..."}
              data={loanStatusDD}
              defaultValue={null}
              setSelected={setSelectedLoanStatus}
              selected={selectedLoanStatus}
              maxMenuHeight={256}
            />
          </section>
        )}
      </>

      <div className="bg-white border border-slate-200 p-3 text-sm mt-3 w-full grid lg:grid-cols-5 grid-cols-1">
        <div className="col-span-1">
          <SubLabeledData
            lable="Total no. of Loanees"
            value={numberOfLoanee || 0}
          />
          <SubLabeledData
            lable="Recovery Percentage"
            value={`${recoveryPercentage || 0} %`}
          />
        </div>

        <div className="grid lg:grid-cols-4 grid-cols-1 lg:col-span-4 col-span-1">
          {/* Group 1: Total Amounts Disbursed*/}
          <div className="flex flex-col bg-blue-50 pl-3 pr-9">
            <SubLabeledData
              lable="Total Principle Outstanding"
              value={`${formatINRCurrency(totalValues?.total_p_o_amount || 0)}`}
            />

            <SubLabeledData
              lable="Total Due Amount"
              value={`${formatINRCurrency(totalValues?.total_di_amount || 0)}`}
            />
          </div>

          <div className="flex flex-col bg-yellow-50 pl-3 pr-9">
            <SubLabeledData
              lable="Total Interest on Interest"
              value={`${formatINRCurrency(totalValues?.total_ioi_due || 0)}`}
            />
            <SubLabeledData
              lable="Total Penal Interest"
              value={`${formatINRCurrency(totalValues?.total_penal_due || 0)}`}
            />
          </div>

          {/* Group 2: Outstanding Amounts */}
          <div className="flex flex-col bg-yellow-50 pl-3 pr-9">
            <SubLabeledData
              lable="Total Interest"
              value={`${formatINRCurrency(
                totalValues?.total_interest_due || 0
              )}`}
            />
          </div>

          {/* Group 3: Paid Amounts */}
          <div className="flex flex-col bg-green-50 pl-3 pr-9">
            <SubLabeledData
              lable="Total Waive Amount"
              value={`${formatINRCurrency(
                totalValues?.total_waiveramount || 0
              )}`}
            />
            <SubLabeledData
              lable="Total Paid Amount"
              value={`${formatINRCurrency(totalValues?.total_rp_amount || 0)}`}
            />

            {/* <SubLabeledData
              lable="Total Interest Paid"
              value={`${formatINRCurrency(totalValues?.total_int_repay || 0)}`}
            /> */}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 mt-3">
        <LoanOTSOutstandingLedgerList
          loanOTSOutstandingLedgerList={loanOTSOutstandingLedgerList}
        />
        {loanOTSOutstandingLedgerList?.length > 0 ? (
          <PrintReport reportTitle={"Sanction Report"}>
            <LoanOTSOutstandingLedgerListPrint
              loanOTSOutstandingLedgerList={loanOTSOutstandingLedgerList}
              startDate={startDate}
              endDate={endDate}
              selectedCategory={selectedCategory}
              selectedApplicantGender={selectedLoanStatus}
              selectedDistrictName={selectedDistrictName}
              selectedConstituencyName={selectedConstituencyName}
            />
          </PrintReport>
        ) : null}
      </div>

      {+loanOTSOutstandingLedgerList[0]?.list_count > 0 && (
        <>
          <section className="mt-4 p-2 bg-ternary bg-opacity-40 border-t-2">
            <H5>Loan Outstanding Ledger</H5>
            <p>
              Total amount :{" "}
              <span className="font-semibold ">
                ₹{+loanOTSOutstandingLedgerList[0]?.total_sanction_amount}
              </span>
            </p>
            <p>
              No of borrower:{" "}
              <span className="font-semibold">
                {+loanOTSOutstandingLedgerList[0]?.list_count}
              </span>
            </p>
          </section>
        </>
      )}
    </Dashboard>
  );
};

export default LoanOTSOutstandingLedgerConfig;

const SubLabeledData = ({ lable, value }) => {
  return (
    <div className="p-1">
      <p className="text-xs text-slate-600">{lable}: </p>
      <p className="font-bold text-slate-800 text-base">{value} </p>
    </div>
  );
};
