import React from "react";
import { NavLink } from "react-router-dom";

import {
  MdEditNote,
  MdOutlineDashboard,
  MdOutlineSchema,
  MdOutlineUploadFile,
  MdOutlinePermMedia,
  MdOutlineFeedback,
} from "react-icons/md";

import { FaIdCardClip, FaRegAddressCard } from "react-icons/fa6";

import { IoDocumentTextOutline, IoNewspaperOutline } from "react-icons/io5";
import { CgAwards } from "react-icons/cg";
import { RiUserSettingsLine } from "react-icons/ri";
import useNotificationHook from "../../authentication/hooks/useNotificationHook";
import { AiOutlineAudit, AiOutlineInfoCircle } from "react-icons/ai";
import SidebarIcons from "../../common/SidebarIcons";
import SidebarLinkGroup from "../../common/SidebarLinkGroup";
import { HiOutlineCog } from "react-icons/hi";
import { BiAddToQueue } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import ReportSidebar from "../common/ReportSidebar";
export default function MDAdminSidebar({
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
}) {
  const { notificationDetail } = useNotificationHook();

  const total_count = notificationDetail?.notificationDetails?.total_count || 0;

  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/dashboard" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/dashboard"
          className={`block  truncate transition duration-150 ${
            pathname === "/mdadmin/dashboard"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/dashboard"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineDashboard size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Dashboard
            </span>
          </div>
        </NavLink>
      </li>
      <SidebarLinkGroup activecondition={pathname.includes("master-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block truncate transition duration-150 ${
                  pathname.includes("master-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("master-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <HiOutlineCog size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Master Config
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-category"
                      className={`block 
                       ${
                         pathname.includes("category")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Category
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-district"
                      className={`block 
                       ${
                         pathname.includes("district")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        District
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-constituency"
                      className={`block 
                       ${
                         pathname.includes("constituency")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Constituency
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-subDivision"
                      className={`block 
                       ${
                         pathname.includes("subDivision")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Sub Division
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-gramPanchayat"
                      className={`block 
                       ${
                         pathname.includes("gramPanchayat")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Gram Panchayat
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-nagarPanchayat"
                      className={`block 
                       ${
                         pathname.includes("nagarPanchayat")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Nagar Panchayat
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-municipalCorporation"
                      className={`block 
                       ${
                         pathname.includes("municipalCorporation")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Municipal Corporation
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-scheme"
                      className={`block 
                       ${
                         pathname.includes("scheme")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Scheme
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-bank"
                      className={`block 
                       ${
                         pathname.includes("bank")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Bank
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-bankbranch"
                      className={`block 
                       ${
                         pathname.includes("bankbranch")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Bank Branch
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-area"
                      className={`block 
                       ${
                         pathname.includes("area")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Area
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-designation"
                      className={`block 
                       ${
                         pathname.includes("designation")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Designation
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/master-cash-counter"
                      className={`block 
                       ${
                         pathname.includes("cash-counter")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Cash Counter
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Application */}
      <SidebarLinkGroup activecondition={pathname.includes("application-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block truncate transition duration-150 ${
                  pathname.includes("application-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("application-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <IoDocumentTextOutline size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Application
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/application-details"
                      className={`block 
                       ${
                         pathname.includes("application-details")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Applicant Details
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/rejected-application-details"
                      className={`block  relative
                       ${
                         pathname.includes("rejected-application-details")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Rejected Applicant Details
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/application-deathcasedetails"
                      className={`block 
                       ${
                         pathname.includes("application-deathcasedetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Death Casae Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Loan Entry */}
      {/* Repayment */}
      <SidebarLinkGroup activecondition={pathname.includes("loanEntry-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("loanEntry-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("loanEntry-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Loan Entry
                    </span>
                  </div>
                  {/* Icon */}
                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/loanEntry-repayment"
                      className={`block 
                       ${
                         pathname.includes("repayment") &&
                         !pathname.includes("repaymentHistory")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Repayment
                      </span>
                    </NavLink>
                  </li>
                </ul>
                {/* <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/loanEntry-decreeRepayment"
                      className={`block 
                       ${
                         pathname.includes("decreeRepayment")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Decree Case Repayment
                      </span>
                    </NavLink>
                  </li>
                </ul> */}
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/loanEntry-oneTimeSettlement"
                      className={`block 
                       ${
                         pathname.includes("oneTimeSettlement")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        One Time Settlement
                      </span>
                    </NavLink>
                  </li>
                </ul>
                {/* <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/loanEntry-repaymentHistory"
                      className={`block 
                       ${
                         pathname.includes("repaymentHistory")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Repayment History
                      </span>
                    </NavLink>
                  </li>
                </ul> */}
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* DemandNotice*/}

      <SidebarLinkGroup activecondition={pathname.includes("notice")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("notice")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("notice")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Notice
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/demand_notice"
                      className={`block 
                       ${
                         pathname.includes("demand_notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Demand Notice
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/direct-dept-notice_notice"
                      className={`block 
                       ${
                         pathname.includes("direct-")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Direct Departmental Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/indirect1-dept-notice"
                      className={`block 
                       ${
                         pathname.includes("indirect1-dept-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Indirect Departmental Notice
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/ots-notice"
                      className={`block 
                       ${
                         pathname.includes("ots-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        OTS Notice
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      <SidebarLinkGroup activecondition={pathname.includes("defaulter-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("defaulter-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <FaRegAddressCard size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Defaulter Details
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/defaulter-defaulterList"
                      className={`block 
                       ${
                         pathname.includes("defaulter-defaulterList")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter List
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/defaulter-uploadDefaulterDetails"
                      className={`block 
                       ${
                         pathname.includes("defaulter-uploadDefaulterDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      {/* {Cash Counter } */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/cash_counter" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/cash_counter"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/cash_counter" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/cash_counter"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <BsBank2 size={20} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Cash Counter
            </span>
          </div>
        </NavLink>
      </li>

      {/* ReportList */}

      <ReportSidebar
        pathname={pathname}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
        accessRole={"mdadmin"}
      />
      {/* ReportList */}

      {/* About Us */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/about_us" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/about_us"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/about_us" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/about_us"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <AiOutlineInfoCircle size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              About Us
            </span>
          </div>
        </NavLink>
      </li>

      {/* Schemes */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/schemes" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/schemes"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/schemes"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/schemes"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineSchema size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Schemes
            </span>
          </div>
        </NavLink>
      </li>
      {/* Awards and appreciation */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/awards_list" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/awards_list"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/awards_list" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/awards_list"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <CgAwards size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Awards &amp; Appreciation
            </span>
          </div>
        </NavLink>
      </li>

      {/* Publication */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/publication" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/publication"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/publication"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/publication"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineUploadFile size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Publications
            </span>
          </div>
        </NavLink>
      </li>

      {/* News and Events */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/news" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/news"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/news"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/news"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <IoNewspaperOutline size={20} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              News &amp; Events
            </span>
          </div>
        </NavLink>
      </li>

      {/* Media Gallary */}
      <SidebarLinkGroup activecondition={pathname.includes("media-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("media-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdOutlinePermMedia size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Media Gallery
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/mdadmin/media-photo"
                      className={`block 
                       ${
                         pathname.includes("photo")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Photo Gallery
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className="mb-1 last:mb-0">
                    <NavLink
                      end
                      to="/mdadmin/media-video"
                      className={`block 
                       ${
                         pathname.includes("video")
                           ? "text-white"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Video Gallery
                      </span>
                    </NavLink>
                  </li> */}
                  <li className="mb-1 last:mb-0">
                    <NavLink
                      end
                      to="/mdadmin/media-broacher"
                      className={`block 
                       ${
                         pathname.includes("broacher")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Brochures &amp; Pamphlets
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Employee Management */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/employee-management" &&
          "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/employee-management"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/employee-management" &&
            "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/employee-management"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <RiUserSettingsLine size={22} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Employee Management
            </span>
          </div>
        </NavLink>
      </li>

      {/* User management */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/user-management" &&
          "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/user-management"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/user-management"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/user-management"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <RiUserSettingsLine size={22} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              User Management
            </span>
          </div>
        </NavLink>
      </li>

      {/* FAQ */}
      {/* <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/faq" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/faq"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/faq"                   ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/faq"
                   ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <FaQuestionCircle />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              FAQs
            </span>
          </div>
        </NavLink>
      </li> */}

      {/* Feed Back */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/feed_back" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/feed_back"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/feed_back" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/feed_back"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineFeedback size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Feedback
            </span>
          </div>
        </NavLink>
      </li>
      {/* Audit Trail */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/mdadmin/auditTrail" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/mdadmin/auditTrail"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/mdadmin/auditTrail"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/mdadmin/auditTrail"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <AiOutlineAudit size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Audit Trail
            </span>
          </div>
        </NavLink>
      </li>
    </ul>
  );
}
